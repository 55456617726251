@import "src/styles/functions";
@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-m-building-special {
  .window-a {
    padding: multiply-size(30px) multiply-size(38px) multiply-size(22px) multiply-size(38px);
  }

  .container {
    flex-direction: column;
  }

  .page {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .inner-html-description {
      text-align: center;
      padding: 0 multiply-size(50px);
      box-sizing: border-box;
      font-size: multiply-size(12px);
    }
  }

  .building-details-container {
    display: flex;
    align-items: center;

    .building-icon {
      width: multiply-size(100px);
      min-width: multiply-size(100px);
      position: relative;
      margin-right: multiply-size(25px);

      img {
        width: 100%;
        object-fit: contain;
      }

      m-ui-exp-up {
        position: absolute;
        bottom: 0;
        width: 100%;
        justify-content: center;
        color: $color-K;
      }
    }

    .building-desc {
      font-size: multiply-size(12px);
      line-height: multiply-size(12px);
      @include archivo-narrow();
    }
  }

  swiper-container {
    flex: 1;
  }

  .title-small {
    font-size: multiply-size(11px);
    font-weight: bold;
    margin: 0 0 multiply-size(8px) 0;
    @include archivo-narrow;
  }

  .unlocked-buildings {
    flex-direction: column;

    m-building-thumbnail {
      margin-right: multiply-size(10px);

      &:last-child {
        margin-right: 0;
      }
    }
  }

  m-ui-requirements,
  m-ui-stock-other,
  m-ui-currency,
  m-ui-product,
  m-ui-parameter {
    margin-top: multiply-size(10px);
    display: flex;
  }

  m-ui-requirements {
    flex-direction: column;
  }

  .parameters {
    display: flex;

    m-ui-stock-other,
    m-ui-product,
    m-ui-currency,
    m-ui-parameter {
      margin-right: multiply-size(20px);

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .arrows-slider {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .arrow-blue-left,
    .arrow-blue-right {
      cursor: pointer;
      padding: multiply-size(5px);
      background-size: contain;
      background-repeat: no-repeat;
      width: multiply-size(20px);
      height: multiply-size(20px);

      &.hide {
        visibility: hidden;
      }
    }

    .arrow-blue-left {
      background-image: var(--asset--ui-arow-blue-left-png);
    }

    .arrow-blue-right {
      background-image: var(--asset--ui-arow-blue-right-png);
    }
  }

  m-ui-costs {
    .costs-container {
      justify-content: center;
    }
  }

  @import "custom/style";
}
