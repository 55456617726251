@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-challenges-difficulty-level {
  display: flex;
  align-items: center;
  justify-content: center;

  * {
    box-sizing: border-box;
    --multiply: 2;
  }

  .ow-dialog {
    margin-top: multiply-size(40px);
    &.primary {
      width: multiply-size(756px);
      height: multiply-size(345px);
    }
  }

  .round-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: multiply-size(10px) 0;
    font-size: multiply-size(20px);
    line-height: multiply-size(12px);
    height: 100%;
    padding: 0 multiply-size(20px);

    &:hover {
      filter: brightness(1.2);
    }


    $colors: (
            'green': #00813D,
            'light-green': #019748,
            'lazure': #00A482,
    );

    p {
      @include archivo-narrow;
      font-size: multiply-size(12px);
      line-height: multiply-size(11px);
      margin: 0;
    }

    &.fixed {
      padding: 0;
      min-width: multiply-size(40px);
      min-height: multiply-size(40px);
    }

    &.round-reverse {
      border-radius: 0 multiply-size(10px);
    }

    i {
      text-shadow: none;
    }

    &.c {
      color: white;

      @each $key, $val in $colors {
        &-#{$key} {
          background-color: #{$val};
        }
      }
    }
  }

  .wrapper {
    width: 100%;
    height: 100%;
    padding: multiply-size(20px);

    nav {
      display: flex;
      justify-content: space-between;
      position: absolute;
      left: multiply-size(16px);
      top: multiply-size(16px);
      width: calc(100% - 64px);
      height: calc(100% - 64px);

      .left,
      .right {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
      }

      .left {
        width: multiply-size(36px);

        .top {
          display: flex;
          gap: multiply-size(8px);
          flex-direction: row-reverse;
          height: multiply-size(36px);
        }
      }

      .right {
        .top, .bottom {
          display: flex;
          flex-direction: column;
          gap: multiply-size(8px);
        }
      }
    }

    .content {
      width: multiply-size(498px);
      margin: auto;
      height: 100%;
      overflow: hidden;

      * {
        @include archivo-narrow;
      }

      .swiper-navigation {
        * {
          @include font-awesome;
        }
        .arrow-pagination{
          position: absolute;
          font-size: multiply-size(20px);
          cursor: pointer;
        }
        .left{
          top:multiply-size(28px);
          left: multiply-size(94px);
        }
        .right {
          top:multiply-size(28px);
          right: multiply-size(94px);
        }
      }

      .challenge{
        border: solid 1px black;
        display: flex;
        align-content: center;
        justify-content: center;
        border-radius: multiply-size(10px) 0;
        font-size:  multiply-size(12px);
        font-weight: bold;
        margin-bottom: multiply-size(14px);
      }

      .decorator {
        position: relative;
        margin: multiply-size(14px) 0;
        background-color: black;
        height: multiply-size(1px);

        p {
          font-size: multiply-size(12px);
          line-height: multiply-size(12px);
          font-weight: bold;
          position: absolute;
          top: multiply-size(-6px);
          margin: 0;
          background-color: white;
          padding-right: multiply-size(6px);
        }
      }

      .progress {
        position: relative;
        padding-top: multiply-size(13px);

        .percentage {
          position: absolute;
          left: calc(50% - 7px);
          margin: 0;
          top: 0;
          font-size: multiply-size(12px);
          line-height: multiply-size(12px);
        }

        .bar {
          width: 100%;
          height: multiply-size(8px);
          border: multiply-size(1px) solid black;
          border-radius: multiply-size(2px);
          position: relative;
          overflow: hidden;
          margin: 0 0 multiply-size(8px);

          .fill {
            top: 0;
            left: 0;
            height: 100%;
            position: absolute;
            background-color: #FF0909;
          }
        }

        .info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: multiply-size(8px);

          div {
            display: flex;
            align-items: center;

            p {
              margin: 0;
              font-size: multiply-size(12px);
              line-height: multiply-size(12px);
              padding: multiply-size(2px) multiply-size(16px) multiply-size(2px) 0;
              border-right: multiply-size(1px) solid black;

              &:last-of-type {
                border: none;
                padding: multiply-size(2px) 0 multiply-size(2px) multiply-size(16px);
              }
            }
          }
        }
      }

      .difficulty {
        margin-bottom: multiply-size(25px);

        .hint {
          font-size: multiply-size(12px);
          line-height: multiply-size(12px);
          margin: multiply-size(4px) 0 multiply-size(8px);
          text-align: center;
          width: 100%;
        }

        .boxes-container {
          display: flex;
          justify-content: space-between;

          .box {
            opacity: 0.2;
            width: multiply-size(156px);
            height: multiply-size(94px);
            border: multiply-size(1px) solid black;
            padding-top: multiply-size(3px);

            &.red {
              background-color: #FF0909;
            }

            &.yellow {
              background-color: #FFCC00;
            }

            &.green {
              background-color: #00813D;
            }

            &.active {
              opacity: 1;
            }


            .info {
              height: multiply-size(84px);
              background-color: white;
              padding: multiply-size(4px) multiply-size(6px);

              .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: multiply-size(8px);
                border-bottom: multiply-size(1px) solid black;

                .name {
                  font-size: multiply-size(12px);
                  line-height: multiply-size(12px);
                  font-weight: bold;
                  margin: 0;
                }

                .votes {
                  display: flex;
                  align-items: center;
                  font-size: multiply-size(12px);
                  line-height: multiply-size(12px);

                  .round-button {
                    width: multiply-size(16px);
                    height: multiply-size(16px);
                    border-radius: 0 multiply-size(4px);
                    padding: 0;
                    margin-left: multiply-size(6px);
                    color: white;
                  }
                }
              }
              .award {
                display: flex;
                justify-content: space-between;
                padding-top: multiply-size(4px);

                .objective {
                  display: flex;

                  .name {
                    font-size: multiply-size(12px);
                    line-height: multiply-size(12px);
                    margin: 0;
                  }

                  .value {
                    font-size: multiply-size(20px);
                    line-height: multiply-size(12px);
                    margin: 0 0 0 multiply-size(4px);
                    font-weight: bold;
                    padding-top: multiply-size(2px);
                  }
                }

                .bonus {
                  display: flex;

                  p {
                    font-size: multiply-size(12px);
                    line-height: multiply-size(12px);
                    margin: 0;
                  }

                  .list {
                    display: flex;
                    flex-direction: column;
                    margin-left: multiply-size(2px);

                    div {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin-bottom: multiply-size(4px);

                      img {
                        margin-left: multiply-size(2px);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .strategy {
        .thresholds {
          display: flex;
          align-items: flex-start;
          padding-top: multiply-size(8px);

          .step {
            display: flex;
            align-items: flex-start;
            padding-right: multiply-size(14px);
            padding-left: multiply-size(14px);
            border-right: multiply-size(1px) solid black;

            &:first-of-type {
              padding-left: 0;
            }

            &:last-of-type {
              border-right: unset;
              padding-right: unset;
            }

            .name {
              font-size: multiply-size(16px);
              line-height: multiply-size(12px);
              font-weight: bold;
              padding-top: multiply-size(1px);
              margin: 0 multiply-size(6px) 0 0;
            }

            .list {
              display: flex;
              flex-direction: column;

              div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: multiply-size(4px);

                p {
                  font-size: multiply-size(12px);
                  line-height: multiply-size(12px);
                  margin: 0 multiply-size(2px) 0;
                }
              }
            }
          }
        }
      }

      .description {
        p {
          margin: 0;
          font-size: multiply-size(12px);
          line-height: multiply-size(12px);
          text-align: justify;
        }
      }
    }
  }
}
