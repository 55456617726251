@import "../../core/variables";
@import "../../core/mixins";

$colors: (
  $font-stroke: #000000,
  $border: #000000,
  $background: (
    $normal: #E60000,
    $active: #a92822,
  ),
  $box-shadow: (
    $normal: #800000,
    $active: #6f0808,
  ),
  $font: (
    $normal: #ffffff,
    $active: #dbada9,
  ),
);

&.red {
  @include generate-schema-button-color($colors);
  text-shadow: none;
}
