@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

hud-player-profile-details {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  @include mikado();
  font-weight: bold;
  font-size: multiply-size(12px);

  a, a:hover {
    text-decoration: none;
  }

  .name {
    margin-bottom: multiply-size(5px);
    font-size: multiply-size(10px);
  }

  app-hud-player {
    margin: multiply-size(20px) 0 0;
  }

  .email {
    font-size: multiply-size(10px);
    @include archivo-narrow();
    font-weight: 700;
  }

  .buttons {
    margin: multiply-size(20px) 0;
    display: flex;

    & > * {
      margin: 0 multiply-size(5px);
    }
  }

  .experience {
    margin: multiply-size(10px);
    font-size: multiply-size(10px);
  }

  #logout {
    color: $color-K;
    border: 0;
    cursor: pointer;
    @include archivo-narrow();
    font-size: multiply-size(10px);
    font-weight: 700;
    width: multiply-size(100px);
    height: multiply-size(40px);
    margin-bottom: multiply-size(8px);
  }

  button.primary.base {
    width: multiply-size(132px);
    font-size: multiply-size(12px);
    padding: 0 0 multiply-size(5px) 0;
  }
}
