@import "src/styles/variables/core/variables";
@import "src/styles/functions";
@import "src/styles/mixins";

app-cookies {
  max-width: 1440px;

  #{$context-mobile} & {
    .wrapper {
      width: 100%;
      max-width: 96%;
    }
  }

  .inner {
    max-height: 100vh;
    background-color: #fff;
    overflow-y: auto;
    padding: multiply-size(15px) multiply-size(15px) multiply-size(30px) multiply-size(15px);
    position: relative;
    border-radius: multiply-size(10px) 0;

    .exit-bar {
      position: absolute;
      top: 0;
      right: 0;

      .exit {
        i {
          font-size: multiply-size(10px) !important;
          color: $color-H !important;
          text-shadow: none !important;
        }
      }
    }

    .title {
      @include archivo-narrow();
      color: #000;
      font-size: multiply-size(10px);
      margin: 0;
    }

    .row {
      @include archivo-narrow();
      color: #000;
    }
  }

  .wrapper {
    overflow-y: auto;
    box-sizing: content-box;
    width: 100%;
    max-width: multiply-size(350px);
    margin: 0 auto;
    padding: 0 multiply-size(5px);
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    z-index: 1;
    margin-top: multiply-size(6px);

    .conf {
      display: flex;
      align-items: flex-end;
    }

    &.paragraphs {
      display: block;
      font-size: multiply-size(10px);
      margin-top: multiply-size(10px);
      margin-bottom: multiply-size(4px);
    }
  }

  .checkbox {
    flex-direction: row;

    label {
      padding: 0 0 0 multiply-size(6px);
      font-size: multiply-size(10px);
      line-height: multiply-size(13px);;
      @include archivo-narrow();
    }

    input {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      opacity: 0;

      &:checked + .custom-checkbox-fill {
        display: block;
      }
    }

    .control-checkbox {
      display: flex;
    }

    .custom-checkbox {
      min-width: multiply-size(20px);
      max-width: multiply-size(20px);
      min-height: multiply-size(20px);
      max-height: multiply-size(20px);
      border: multiply-size(1px) solid #000;
      color: #000;
      border-radius: multiply-size(3px);
      position: relative;
      box-sizing: border-box;

      &.disabled {
        border: multiply-size(1px) solid #d2d2d2;
        color: #d2d2d2;
      }

      .custom-checkbox-fill {
        display: none;
        position: absolute;
        @include absolute-center;
        font-size: multiply-size(12px);
        pointer-events: none;
      }
    }

    padding: multiply-size(6px);
  }

  a {
    color: #ed1c24;
    text-decoration: none;
    cursor: pointer;
  }


  label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }

  input[type="radio"], input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
  }

  .row-btn {
    display: flex;
    gap: multiply-size(15px);
    align-items: center;
    justify-content: center;

    button {
      width: multiply-size(100px);
      height: multiply-size(20px);
      @include archivo-narrow();
      font-size: multiply-size(10px);
      line-height: multiply-size(17px);
      box-shadow: none !important;
      border: 0 !important;
      border-radius: multiply-size(5px) 0;
      padding: 0 !important;
    }
  }

  .submit-container.third {
    display: flex;
    align-items: center;
  }

  button.base {
    border: multiply-size(1px) solid #000000;
  }

  m-ui-close-button {
    button.exit.normal {
      width: multiply-size(20px);
      height: multiply-size(20px);
    }
  }
}
