@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-mgm-invitee {
  @import "../styles";

  .window-a {
    padding: multiply-size(20px) multiply-size(38px) multiply-size(12px) multiply-size(38px);
  }

  .title {
    p {
      margin-bottom: 0;
    }
  }

  .img-currency-big {
    width: multiply-size(38px);
    height: multiply-size(38px);
  }

  .player-code {
    width: 80%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: multiply-size(15px) auto multiply-size(5px) auto;
    flex-direction: row;

    span {
      font-size: multiply-size(9px);
      font-weight: normal;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .player-prize {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .prize-box {
      flex-direction: row;
    }

    p {
      font-weight: normal;
      font-size: multiply-size(9px);
    }
  }

  .no-code {
    margin-top: multiply-size(24px) !important;
    font-size: multiply-size(8px);
  }

  .margin-v1 {
    margin: multiply-size(14px) auto multiply-size(44px) auto;
  }

  .left {
    text-align: left !important;
  }

  form {
    margin-top: multiply-size(52px);
  }

  .info {
    @include archivo-narrow;
    font-size: multiply-size(8px);
    text-align: center;
    color: $color-G;
  }

  input {
    @include myriad;
    color: $color-K;
    border: multiply-size(2px) solid $color-K;
    height: multiply-size(34px);
    width: multiply-size(92px);
    font-size: multiply-size(9px);
    box-sizing: border-box;
    background: $color-I;
    text-align: center;
    border-radius: multiply-size(10px);
    margin-left: multiply-size(8px);
    padding-top: multiply-size(2px);
  }

  .readonly {
    opacity: 0.6;
  }

  button.base {
    min-width: multiply-size(54px) !important;
    height: multiply-size(34px) !important;
    margin-left: multiply-size(4px);
    border-radius: multiply-size(10px);
    font-size: multiply-size(12px);
    text-shadow: none;
  }
}
