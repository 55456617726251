@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-plan-details {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  * {
    box-sizing: border-box;
  }

  .ow-dialog {
    &.primary {
      width: multiply-size(756px);
      height: multiply-size(345px);
      box-shadow: unset;
      overflow: auto;
    }
  }

  .round-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: multiply-size(10px) 0;
    font-size: multiply-size(20px);
    line-height: multiply-size(12px);
    height: 100%;
    padding: 0 multiply-size(20px);

    &:hover {
      filter: brightness(1.2);
    }

    $colors: (
      "green": #00813d,
      "light-green": #019748,
      "lazure": #00a482,
    );

    p {
      @include archivo-narrow;
      font-size: multiply-size(14px);
      line-height: multiply-size(11px);
      margin: 0;
    }

    &.fixed {
      padding: 0;
      min-width: multiply-size(40px);
      min-height: multiply-size(40px);
    }

    &.round-reverse {
      border-radius: 0 multiply-size(10px);
    }

    i {
      text-shadow: none;
    }

    &.c {
      color: white;

      @each $key, $val in $colors {
        &-#{$key} {
          background-color: #{$val};
        }
      }
    }
  }

  .wrapper {
    width: 100%;
    height: 100%;
    padding: multiply-size(20px) multiply-size(20px) 0;
    min-width: multiply-size(700px);

    app-business-navigation {
      .business-navigation {
        max-width: multiply-size(720px);
        //@media screen and (max-width: 700px) {
        //  min-width: unset;
        //}
      }
    }

    nav {
      display: flex;
      justify-content: space-between;
      position: absolute;
      left: multiply-size(16px);
      top: multiply-size(16px);
      //width: calc(100% - 64px);
      height: calc(100% - 64px);

      .left,
      .right {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
      }

      .left {
        width: multiply-size(36px);

        .top {
          display: flex;
          gap: multiply-size(8px);
          flex-direction: row-reverse;
          height: multiply-size(36px);
        }
      }

      .right {
        .top,
        .bottom {
          display: flex;
          flex-direction: column;
          gap: multiply-size(8px);
        }
      }
    }

    .content {
      padding: 0 multiply-size(30px);
      width: multiply-size(558px);
      margin: auto;
      height: 100%;
      overflow: hidden;

      * {
        @include archivo-narrow;
      }

      .swiper-navigation {
        position: relative;

        * {
          @include font-awesome;
        }

        .arrow-pagination {
          position: absolute;
          font-size: multiply-size(20px);
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: multiply-size(40px);
          height: multiply-size(40px);

          &:hover {
            opacity: 0.8;
          }
        }

        .left {
          top: 0;
          left: multiply-size(-40px);
        }

        .right {
          top: 0;
          right: multiply-size(-40px);
        }
      }

      .plan-details {
        .title-container {
          border: solid 1px black;
          display: flex;
          flex-wrap: wrap;
          align-content: center;
          justify-content: center;
          border-radius: multiply-size(10px) 0;
          height: multiply-size(40px);
          position: relative;

          img {
            width: multiply-size(90px);
            height: multiply-size(28px);
            object-fit: contain;
          }

          p {
            font-size: multiply-size(14px);
            font-weight: bold;
          }
        }
        .badge {
          @include archivo-narrow;
          display: flex;
          justify-content: center;
          width: multiply-size(208px);
          height: multiply-size(20px);
          font-size: multiply-size(14px);
          line-height: multiply-size(12px);
          border-radius: 0 0 multiply-size(10px) multiply-size(10px);
          padding: 0 multiply-size(24px);
          color: white;
          text-align: center;
          align-items: center;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: multiply-size(8px);

          p {
            margin: 0;
            padding: 0;
            font-size: multiply-size(12px);
            line-height: inherit;
            font-weight: normal;
          }

          &.red {
            background-color: #ff0909;
          }

          &.yellow {
            background-color: #ff9100;
          }

          &.green {
            background-color: #00813d;
          }
        }
        ow-perfect-scrollbar {
          width: 100%;
          overflow: hidden;
          height: multiply-size(264px);

          @media screen and (max-width: 1080px) {
            max-height: calc(100vh - 150px);
          }

          .activity-name {
            font-size: multiply-size(14px);
            line-height: multiply-size(12px);
            margin-bottom: multiply-size(10px);

            text-align: center;
            width: multiply-size(498px);
          }

          .decorator {
            margin-bottom: multiply-size(5px);
            position: relative;
            background-color: black;
            height: multiply-size(1px);
            width: multiply-size(498px);

            p {
              font-size: multiply-size(12px);
              line-height: multiply-size(12px);
              font-weight: bold;
              position: absolute;
              top: multiply-size(-6px);
              margin: 0;
              background-color: white;
              padding-right: multiply-size(6px);
            }
          }

          .progress {
            @include archivo-narrow;
            position: relative;
            margin-bottom: multiply-size(20px);
            padding-top: multiply-size(13px);
            height: multiply-size(49px);

            .percentage {
              position: absolute;
              margin: 0;
              top: 0;
              font-size: multiply-size(14px);
              line-height: multiply-size(12px);
              margin-bottom: multiply-size(2px);
              transition: left 0.2s ease-in-out;
            }

            .bar {
              width: 100%;
              height: multiply-size(8px);
              border: multiply-size(1px) solid black;
              border-radius: multiply-size(2px);
              position: relative;
              overflow: hidden;
              margin: 0 0 multiply-size(8px);

              .fill {
                top: 0;
                left: 0;
                height: 100%;
                position: absolute;
                background-color: #ff0909;
                transition: width 0.2s ease-in-out;
              }
            }

            .info {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: multiply-size(8px);

              div {
                display: flex;
                align-items: center;

                p {
                  margin: 0;
                  font-size: multiply-size(14px);
                  line-height: multiply-size(12px);
                  padding: multiply-size(2px) 0 multiply-size(2px) 0;

                  &:nth-child(1) {
                    padding: multiply-size(2px) multiply-size(16px) multiply-size(2px) 0;
                  }

                  &:nth-child(n + 2) {
                    padding: multiply-size(2px) 0 multiply-size(2px) multiply-size(16px);
                    border-left: multiply-size(1px) solid black;
                  }
                }
              }
            }
          }

          .difficulty {
            margin-bottom: multiply-size(25px);
            width: multiply-size(498px);

            .boxes-container {
              display: flex;
              justify-content: space-between;

              .box {
                width: multiply-size(156px);
                height: multiply-size(94px);
                border: multiply-size(1px) solid black;
                padding-top: multiply-size(3px);

                &.red {
                  background-color: #ff0909;
                }

                &.yellow {
                  background-color: #ffcc00;
                }

                &.green {
                  background-color: #00813d;
                }

                .info {
                  height: multiply-size(84px);
                  background-color: white;
                  padding: multiply-size(4px) multiply-size(6px);

                  .header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-bottom: multiply-size(8px);
                    border-bottom: multiply-size(1px) solid black;

                    .name {
                      font-size: multiply-size(14px);
                      line-height: multiply-size(12px);
                      font-weight: bold;
                      margin: 0;
                    }

                    .votes {
                      display: flex;
                      align-items: center;
                      font-size: multiply-size(14px);
                      line-height: multiply-size(12px);

                      .round-button {
                        width: multiply-size(16px);
                        height: multiply-size(16px);
                        border-radius: 0 multiply-size(4px);
                        padding: 0;
                        margin-left: multiply-size(6px);
                        color: white;
                      }
                    }
                  }

                  .award {
                    display: flex;
                    justify-content: space-between;
                    padding-top: multiply-size(4px);

                    .objective {
                      display: flex;

                      .name {
                        font-size: multiply-size(14px);
                        line-height: multiply-size(12px);
                        margin: 0;
                      }

                      .value {
                        font-size: multiply-size(20px);
                        line-height: multiply-size(12px);
                        margin: 0 0 0 multiply-size(4px);
                        font-weight: bold;
                        padding-top: multiply-size(2px);
                      }
                    }

                    .bonus {
                      display: flex;

                      p {
                        font-size: multiply-size(14px);
                        line-height: multiply-size(12px);
                        margin: 0;
                      }

                      .list {
                        display: flex;
                        flex-direction: column;
                        margin-left: multiply-size(2px);

                        div {
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          margin-bottom: multiply-size(4px);

                          img {
                            margin-left: multiply-size(2px);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          m-ui-currency {
            &.bonus-currency {
              .stock-j {
                margin-left: multiply-size(4px);
              }
            }

            .stock-j {
              margin-bottom: 0;

              .value {
                text-shadow: unset;
                color: black;
                @include archivo-narrow();
                font-size: multiply-size(14px);
                font-weight: 400;
              }

              .icon {
                margin-right: multiply-size(4px);
              }
            }
          }

          .prizes-strategy {
            width: multiply-size(498px);

            .thresholds {
              display: flex;
              align-items: flex-start;
              padding-top: multiply-size(4px);
              margin-bottom: multiply-size(24px);

              .step {
                display: flex;
                align-items: flex-start;
                padding-right: multiply-size(14px);
                padding-left: multiply-size(14px);
                border-right: multiply-size(1px) solid black;

                &:first-of-type {
                  padding-left: 0;
                }

                &:last-of-type {
                  border-right: unset;
                  padding-right: unset;
                }

                .name {
                  font-size: multiply-size(16px);
                  line-height: multiply-size(12px);
                  font-weight: bold;
                  padding-top: multiply-size(1px);
                  margin: 0 multiply-size(6px) 0 0;
                }

                .list {
                  display: flex;
                  flex-direction: column;

                  div,
                  p {
                    font-size: multiply-size(14px);
                    margin: multiply-size(2px) 0;
                    display: flex;
                    align-items: center;
                    gap: multiply-size(2px);

                    span {
                      display: flex;
                      align-items: center;
                    }

                    span:not(:first-of-type) {
                      div {
                        margin-left: 4px;
                      }
                    }
                  }

                  img {
                    margin: 0 multiply-size(4px);
                  }

                  .player-rewarded-prizes-wrapper {
                    display: flex;
                    gap: multiply-size(4px);
                  }
                }
              }
            }
          }

          .prizes-pay-out {
            width: multiply-size(498px);

            .thresholds {
              display: flex;
              align-items: flex-start;
              padding-top: multiply-size(6px);
              margin-bottom: multiply-size(10px);

              .step {
                display: flex;
                align-items: flex-start;
                padding-right: multiply-size(14px);
                padding-left: multiply-size(14px);
                border-right: multiply-size(1px) solid black;

                &:first-of-type {
                  padding-left: 0;
                }

                &:last-of-type {
                  border-right: unset;
                  padding-right: unset;
                }

                .name {
                  font-size: multiply-size(16px);
                  line-height: multiply-size(12px);
                  font-weight: bold;
                  padding-top: multiply-size(1px);
                  margin: 0 multiply-size(6px) 0 0;
                }

                .list {
                  display: flex;
                  flex-direction: column;

                  div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: multiply-size(4px);

                    p {
                      font-size: multiply-size(14px);
                      line-height: multiply-size(12px);
                      margin: 0 multiply-size(2px) 0;
                    }
                  }
                }
              }
            }
          }

          .description {
            width: multiply-size(498px);

            .decorator {
              margin-bottom: multiply-size(15px);
            }

            p {
              margin-top: 0;
              font-size: multiply-size(12px);
              line-height: multiply-size(12px);
              text-align: justify;
            }

            p.text {
              margin-top: multiply-size(10px);
            }
            table {
              border-collapse: collapse;
            }

            th, td {
              text-align: center; /* Align text to the left in table headers and cells */
              padding: 8px; /* Add some padding inside the table cells for better readability */
              border: 1px solid black; /* 1px solid black border for each cell */
            }
          }
        }
      }

      .client-list {
        .plan {
          border: solid 1px black;
          display: flex;
          flex-wrap: wrap;
          align-content: center;
          justify-content: center;
          border-radius: multiply-size(10px) 0;
          height: multiply-size(40px);

          img {
            width: multiply-size(90px);
            height: multiply-size(28px);
          }

          p {
            font-size: multiply-size(14px);
            font-weight: bold;
          }
        }

        .task {
          font-size: multiply-size(14px);
          line-height: multiply-size(12px);
          margin-top: multiply-size(6px);
          text-align: center;
          width: 100%;
        }

        .decorator {
          margin-bottom: multiply-size(5px);
          position: relative;
          background-color: black;
          height: multiply-size(1px);

          p {
            font-size: multiply-size(14px);
            line-height: multiply-size(12px);
            font-weight: bold;
            position: absolute;
            top: multiply-size(-6px);
            margin: 0;
            background-color: white;
            padding-right: multiply-size(6px);
          }
        }

        .progress {
          @include archivo-narrow;
          position: relative;
          margin-bottom: multiply-size(10px);
          padding-top: multiply-size(13px);
          height: multiply-size(49px);

          .percentage {
            position: absolute;
            margin: 0;
            top: 0;
            font-size: multiply-size(14px);
            line-height: multiply-size(12px);
            margin-bottom: multiply-size(2px);
            transition: left 0.2s ease-in-out;
          }

          .bar {
            width: 100%;
            height: multiply-size(8px);
            border: multiply-size(1px) solid black;
            border-radius: multiply-size(2px);
            position: relative;
            overflow: hidden;
            margin: 0 0 multiply-size(8px);

            .fill {
              top: 0;
              left: 0;
              height: 100%;
              position: absolute;
              background-color: #ff0909;
              transition: width 0.2s ease-in-out;
            }
          }

          .info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: multiply-size(12px);
            height: multiply-size(20px);

            .left,
            .right {
              display: flex;
              align-items: center;
              height: 100%;

              p {
                margin: 0;
                font-size: multiply-size(14px);
                line-height: multiply-size(16px);
                padding: multiply-size(2px) multiply-size(16px) multiply-size(2px) 0;

                &:not(:only-child) {
                  border-right: multiply-size(1px) solid black;
                }

                &:last-of-type:not(:only-child) {
                  border: none;
                  padding: multiply-size(2px) 0 multiply-size(2px) multiply-size(16px);
                }
              }
            }

            .left {
              p:first-of-type {
                padding-left: 0;
              }
            }
          }
        }

        .search {
          margin-top: multiply-size(15px);
          position: relative;

          .search-bar {
            input {
              text-align: left;
              border: solid multiply-size(1px) black;
              border-radius: multiply-size(10px) 0;
              width: multiply-size(198px);
            }

            .fa-search {
              @include font-awesome;
              position: relative;
              right: multiply-size(22px);
              font-size: multiply-size(15px);
              top: multiply-size(1px);
            }
          }
        }

        .table {
          width: 100%;
          margin: auto;
          overflow: hidden;
          height: 100%;

          ow-perfect-scrollbar {
            height: multiply-size(165px);
          }

          .table-headers {
            position: relative;
            margin-top: multiply-size(18px);
            height: multiply-size(14px);

            button {
              color: black;
              position: absolute;
              height: multiply-size(14px);
              font-size: multiply-size(14px);

              i {
                margin-left: multiply-size(6px);
                @include font-awesome;
                width: multiply-size(10px);
                text-shadow: none;
              }
            }

            .header-name {
              left: 0px;
            }

            .header-id {
              right: multiply-size(86px);
            }

            .header-realization {
              right: 0px;
            }
          }

          .mat-mdc-table {
            margin-top: multiply-size(10px);
            width: 100%;
            border-spacing: 0px;
            box-shadow: unset;
          }

          .mat-column-name {
            width: multiply-size(331px);
          }

          .mat-column-numberID {
            text-align: center;
          }

          .mat-column-realization {
            text-align: center;
          }

          .mat-mdc-row {
            height: multiply-size(20px);
          }

          .mat-mdc-cell {
            color: black;
            font-size: multiply-size(14px);
            border-bottom: multiply-size(1.5px) solid #000;
          }

          .no-data-match {
            text-align: center;
            font-size: 24px;
          }
        }
      }
    }
  }

}
