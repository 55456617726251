@import "../../core/variables";
@import "../../core/mixins";

$colors: (
        $font-stroke: transparent,
        $border: #000000,
        $background: (
                $normal: #00813D,
                $active: #1F6E2B,
        ),
        $box-shadow: (
                $normal: #15591F,
                $active: #15591F,
        ),
        $font: (
                $normal: #ffffff,
                $active: #cfffe3,
        )
);

&.secondary {
  @include generate-schema-button-color($colors)
}
