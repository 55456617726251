@import "../variables";
@import "../mixins";

$colors: (
  $font-stroke: #4c0008,
  $border: #4c0008,
  $background: (
    $normal: #e33932,
    $active: #a92822,
  ),
  $box-shadow: (
    $normal: #99231e,
    $active: #6f0808,
  ),
  $font: (
    $normal: #ffffff,
    $active: #dbada9,
  ),
);

&.tertiary {
  @include generate-schema-button-color($colors);
}
