@import "src/styles/functions";

// no-multiply-size-file
app-oauth-confirm {
  @import "../../../styles/core/styles";

  .control {
    margin: 18px 0 18px 0;

    &:first-child {
      margin: 0 0 40px;
    }

    .label-flex {
      margin-bottom: multiply-size(5px);
    }

    label {
      @include archivo-narrow();
      font-size: multiply-size(12px);
      font-weight: 400;
      padding-left: multiply-size(10px);
    }

    input, textarea {
      border: multiply-size(1px) solid #000;
      border-radius: multiply-size(10px) 0 multiply-size(10px) 0;
      text-align: left;
      font-weight: 400;
      padding: multiply-size(8px) multiply-size(10px);
      width: multiply-size(226px);
      min-width: multiply-size(226px);
      max-width: multiply-size(226px);
      font-size: multiply-size(12px);
      box-sizing: border-box;
      @include archivo-narrow();
    }

    input {
      height: multiply-size(30px);
    }

    .alert {
      font-size: multiply-size(10px);
      position: relative;

      span {
        position: absolute;
        top: multiply-size(2px);
      }
    }

    &.control-checkbox {
      max-width: multiply-size(226px);

      .custom-checkbox {
        border: multiply-size(1px) solid #000;
        border-radius: multiply-size(10px) 0 multiply-size(10px) 0;
        width: multiply-size(30px);
        height: multiply-size(30px);
        min-width: multiply-size(30px);
        min-height: multiply-size(30px);
        max-width: multiply-size(30px);
        max-height: multiply-size(30px);
        margin-top: multiply-size(5px);
      }

      label {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0 0 multiply-size(10px);
        z-index: 1;

        a {
          margin-left: multiply-size(3px);
        }
      }
    }
  }
}
