@import "../../../../../../../../../styles/functions";
@import "../../../../../../../../../styles/variables/core/variables";
@import "../../../../../../../../../styles/mixins";

app-mission-group-details {
  @import "variables";

  .global-dialog {
    padding: multiply-size(20px) multiply-size(30px) multiply-size(8px) multiply-size(30px);
  }

  .container {
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  }

  .products-collected {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    flex: 1;

    .artefact {
      width: multiply-size(82px);
      height: multiply-size(94px);
      box-sizing: border-box;
      margin: 0 multiply-size(8px) 0 0;
      position: relative;

      .check-image {
        @include absolute-center-horizontal;
        display: none;
        width: multiply-size(22px);
        bottom: multiply-size(25px);
        z-index: 1;
      }

      &.collected {
        .check-image {
          display: block;
        }

        .card {
          opacity: 0.6;
        }
      }

      &.disabled {
        pointer-events: none;
        cursor: initial;
      }

      &:nth-child(3),
      &:nth-child(6) {
        margin-right: 0;
      }

      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        margin-bottom: 0;
      }

      .card {
        transition: transform 1s;
        position: relative;
        width: 100%;
        height: 100%;
        border: multiply-size(2px) solid $card-border;
        border-radius: multiply-size(14px);
        background-color: $card-background;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        color: $card-color;

        &.empty {
          justify-content: center;
          font-size: multiply-size(20px);
          cursor: initial;
        }

        .icon-production {
          width: multiply-size(36px);
          height: multiply-size(36px);

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .name {
          width: 100%;
          box-sizing: border-box;
          @include archivo-narrow;
          font-size: multiply-size(10px);
          font-weight: bold;
          padding: 0 multiply-size(5px);
          text-align: center;
          margin: multiply-size(3px) 0;
          line-height: 1.1;
          height: multiply-size(30px);
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .description {
          font-size: multiply-size(12px);
        }

        .lock {
          font-weight: bold;
          font-size: multiply-size(12px);
          text-align: center;
          text-transform: uppercase;
          margin: multiply-size(43px) 0 0 0;
          height: multiply-size(50px);
        }

        .worth {
          @include absolute-center-horizontal;
          @include archivo-narrow;
          color: $worth-color;
          bottom: multiply-size(-9px);
          width: multiply-size(50px);
          height: multiply-size(20px);
          font-weight: bold;
          background-color: $worth-background;
          border-radius: multiply-size(5px);
          white-space: nowrap;
          font-size: multiply-size(10px);
          display: flex;
          justify-content: center;
          align-items: center;

          &.check {
            background-color: $worth-check-background;
            box-shadow: 0 0 0 multiply-size(2px) $worth-check-box-shadow;
            @include text-stroke;
          }
        }
      }
    }
  }

  .arrow {
    height: multiply-size(20px);
    width: multiply-size(20px);
    box-sizing: border-box;
    margin-bottom: multiply-size(20px);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .prize {
    height: multiply-size(208px);
    width: multiply-size(122px);
    box-shadow: inset 0 0 0 multiply-size(2px) $prize-box-shadow;
    border-radius: multiply-size(14px);
    background-color: $prize-background;
    box-sizing: border-box;
    @include archivo-narrow;
    @include text-stroke;
    color: $prize-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: multiply-size(10px);
    font-weight: bold;
    margin-bottom: multiply-size(16px);
    margin-left: multiply-size(4px);

    .prize-title {
      @include mikado();
      font-size: multiply-size(10px);
      margin: 0;
      text-align: center;
      line-height: 1.2;
    }

    .reward {
      margin-top: multiply-size(6px);
    }

    img {
      &.chest {
        width: multiply-size(120px);
        height: multiply-size(87px);
        object-fit: contain;
      }

      &.has-received {
        width: multiply-size(20px);
        height: multiply-size(20px);
        object-fit: contain;
        margin-top: multiply-size(14px);
      }
    }
  }

  .hide {
    display: none !important;
  }

  .navigation {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .prev-coll,
    .next-coll {
      display: flex;
      align-items: center;
      @include mikado();
      font-weight: bold;
      font-size: multiply-size(10px);
    }

    .prev {
      margin-right: multiply-size(6px);
    }

    .next {
      margin-left: multiply-size(6px);
    }
  }

  button {
    &.base {
      min-width: 100% !important;
    }
  }
}
