@import "src/styles/functions";

app-custom-login {
  @import "../../../../base/styles/core/styles";
  display: flex;
  height: 100%;

  #{$context-mobile} & {
    .background-img {
      @media screen and (orientation: landscape) {
        padding-bottom: 0;
      }
    }

    .mobile-view.v1 {
      padding-top: multiply-size(14px);
      padding-bottom: multiply-size(36px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .buttons {
      &.check-mission {
        button.landing {
          margin-top: calc(10px * var(--multiply));
        }

        .register {
          margin-top: multiply-size(10px);

          a {
            text-decoration: none;
          }
        }
      }

      .report {
        margin-top: multiply-size(10px);
      }
    }
  }

  .description {
    display: block;
    margin: multiply-size(5px) 0;

    p {
      margin: 0;
    }

    &.error {
      color: #ff3c00;
      text-align: center;
      font-size: multiply-size(10px);
    }
  }

  .add-button {
    display: none;

    &.add-button-show {
      display: block;
    }
  }

  .control {
    &.control-checkbox {
      &.remember-me-container {
        margin-top: multiply-size(20px);
        align-items: center;
        justify-content: center;

        .custom-checkbox {
          min-width: multiply-size(30px);
          max-width: multiply-size(30px);
          min-height: multiply-size(30px);
          max-height: multiply-size(30px);
          border-radius: 25%;
        }

        label {
          padding: 0;
          padding-left: multiply-size(8px);
        }
      }
    }
  }
}
.a2hs__container{
  .a2hs__logo{
    svg{
      background-color: white;
      height: multiply-size(60px);
      width: multiply-size(60px);
    }
  }
}
