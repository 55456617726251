@import "src/styles/functions";
@import "src/styles/variables/core/variables";

app-hud {
  .top-left-container {
    position: relative;
    display: flex;
    margin-top: multiply-size(3px);
    left: multiply-size(3px);
    pointer-events: none;

    #{$context-desktop} & {
      --multiply: 2;
    }

    #{$context-tablet} & {
      --multiply: 1.5;
    }

    & > *:not(.hud-parameters) {
      pointer-events: all;
    }
  }

  .hud-menu {
    position: absolute;
    top: multiply-size(68px);
    left: multiply-size(3px);
    z-index: 1;

    #{$context-desktop} & {
      --multiply: 2;
    }

    #{$context-tablet} & {
      --multiply: 1.5;
    }
  }

  .hud-resources {
    pointer-events: all;
    position: absolute;
    right: 0;
    top: 0;
    padding: multiply-size(1px) multiply-size(4px) 0 0;
    z-index: 1;

    #{$context-desktop} & {
      --multiply: 2;
    }

    #{$context-tablet} & {
      --multiply: 1.5;
    }
  }

  .start-scene-container {
    position: fixed;
    z-index: 9;
    margin-left: 50%;
    display: none;
  }

  @import "custom/styles";
}
