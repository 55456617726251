@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-prepaid-card-dashboard {
  .ow-dialog {
    padding: multiply-size(40px) multiply-size(30px);

    .bottom-bar {
      padding: 0 multiply-size(28px);
      width: 100%;
      justify-content: space-between;

      .bar-button {
        margin: 0;

        .base {
          width: multiply-size(160px);
        }

        .red {
          width: multiply-size(126px);
        }

        button {
          width: inherit;
        }
      }
    }
  }

  .form {
    @include archivo-narrow;

    p {
      margin: 0 0 multiply-size(30px);
      font-size: multiply-size(10px);
      line-height: multiply-size(12px);
    }

    label {
      margin: 0 0 multiply-size(6px);
      font-size: multiply-size(12px);
      line-height: multiply-size(12px);
    }

    form {
      display: flex;
      flex-direction: column;

      input {
        width: multiply-size(159px);
        height: multiply-size(28px);
        border: multiply-size(2px) solid #000;
        border-radius: multiply-size(5px);

        &.ng-dirty.ng-invalid {
          border-color: #ff0000;
        }
      }

      div {
        display: flex;
        gap: multiply-size(6px);
      }
    }
  }

  .details {
    .description {
      @include archivo-narrow;
      font-size: multiply-size(10px);
      line-height: multiply-size(12px);
      margin: 0 0 multiply-size(20px);
    }

    .card-details {
      display: flex;
      align-items: center;
      @include archivo-narrow;
      margin-bottom: multiply-size(15px);
      font-size: multiply-size(10px);
      line-height: multiply-size(12px);
      height: multiply-size(20px);

      p {
        margin: 0;
      }

      .card-number {
        margin-left: multiply-size(4px);
        margin-right: multiply-size(10px);
      }

      .status {
        border-left: multiply-size(1px) solid #000;
        padding-left: multiply-size(10px);
        line-height: multiply-size(20px);
        height: 100%;

        &.active {
          color: #00813D;
        }

        &.inactive {
          color: #FF0909;
        }
      }
    }
  }

  button.base.red,
  button.primary.base {
    @include mikado;
    font-size: multiply-size(12px);
    line-height: multiply-size(16px);
    font-weight: bold;
  }

  button.primary.base {
    min-width: multiply-size(81px);
  }
}
