@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-hud-business {
  height: 100dvh;

  & > * {
    box-sizing: border-box;
  }

  .hud-resources-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: multiply-size(92px);
    height: 100%;
    padding: 0 multiply-size(6px) multiply-size(6px) multiply-size(6px);
  }

  .top,
  .bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: multiply-size(8px);

    .hud-button {
      display: flex;
      align-items: center;
      height: multiply-size(36px);

      &:active {
        button {
          box-shadow: inset 0 calc(3px * var(--multiply)) 0 0 #15591F;
          padding-bottom: 0;
          background: #358F42;
        }
      }

      &.active {
        i, p {
          opacity: 0.3;
        }
      }

      &.not-available {
        opacity: 0.2;
      }

      button {
        display: flex;
        justify-content: space-between;
        border: multiply-size(2px) solid black;
        background-color: #358F42;
        box-shadow: inset 0 multiply-size(-4px) 0 0 #15591F;
        border-radius: multiply-size(8px);
        height: multiply-size(28px);
        padding: 0 multiply-size(6px) multiply-size(2px) multiply-size(6px);
        width: 100%;

        .message-count {
          position: absolute;
          top: multiply-size(-10px);
          left: multiply-size(-10px);
          background: #358F42;
          border-radius: 50%;
          border: multiply-size(2px) solid #000;
          height: multiply-size(15px);
          width: multiply-size(15px);
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: multiply-size(10px);
        }
      }

      i, p {
        font-size: multiply-size(12px);
        line-height: multiply-size(14px);
        text-shadow: unset;
      }

      p {
        @include archivo-narrow;
        margin: 0;
        padding: 0;
      }
    }
  }
  .bottom{
    margin-bottom: multiply-size(8px);
    button{
      pointer-events: all;
    }
  }
  .top{
    margin-top: multiply-size(4px);
    button{
      pointer-events: all;
    }
  }
}
