@import "src/styles/functions";

m-ui-multi-stock {
  @import "variables";

  cursor: pointer;

  .multi-products-container {
    position: absolute;
    display: flex;
    background: $bgc-container;
    padding: multiply-size(5px) multiply-size(24px) multiply-size(2px) multiply-size(5px);
    border: multiply-size(1px) solid $border-color;
    border-radius: multiply-size(6px);
    left: multiply-size(60px);
    z-index: 1;
    top: multiply-size(-6px);

    & > *:not(:first-child) {
      margin: 0 multiply-size(5px);
    }
  }

  m-ui-currency {
    cursor: default;
  }

  @import "custom/style";
}
