@import "src/styles/functions";
@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-m-tile-buy {
  .window-d {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: multiply-size(300px);
    max-width: multiply-size(300px);
  }

  p {
    &.title {
      margin: 0 0 multiply-size(6px) 0;
      font-size: multiply-size(14px);
      @include mikado();
      font-weight: bold;
      @include text-stroke;
      color: $color-I;
      text-align: center;
      text-transform: uppercase;
    }

    &.description {
      @include archivo-narrow;
      margin: 0;
      text-align: center;
      font-size: multiply-size(12px);
      padding: 0 multiply-size(20px);
    }
  }

  m-ui-costs {
    margin-top: multiply-size(10px);
  }

  @import "custom/style";
}
