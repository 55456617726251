@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-hud-menu {
  @import "variables";

  .player-info-container {
    $bgc-wipe: $color-I;
    $border: $color-K;
    $font-color: $color-K;

    width: fit-content;
    height: multiply-size(24px);
    background-color: $bgc-wipe;
    border: multiply-size(2px) solid $border;
    border-left: none;
    border-radius: multiply-size(6px);
    font-size: multiply-size(10px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    @include mikado();
    font-weight: 700;
    margin: multiply-size(8px) 0;
    color: $font-color;
    padding-right: multiply-size(6px);
    pointer-events: all;

    button {
      margin-right: multiply-size(6px);
    }
  }

  .main-buttons-container {
    display: flex;
    flex-direction: column;

    .buttons-vertical {
      display: flex;
    }

    .main-button {
      height: multiply-size(40px);
      width: multiply-size(40px);
      margin-bottom: multiply-size(6px);
      pointer-events: all;
      @include notificationBounce;
      position: relative;

      img {
        height: multiply-size(40px);
        width: multiply-size(40px);
      }

      .app-notification {
        right: 0;
        top: 0;
      }

      &:hover {
        filter: var(--filter-hover);
      }

      &.anim {
        transform: translateX(-115%) scale3d(0, 1, 1);
        pointer-events: none;
        opacity: 0;

        &.active {
          opacity: 1;
          pointer-events: initial;
          transform: translateX(0) scale3d(1, 1, 1);
        }
      }

      ow-object-svg {
        width: 100%;
        height: 100%;
      }
    }

    .buttons-vertical > .buttons-vertical {
      .main-button {
        transition: 0.5s;
        margin-left: multiply-size(6px);
      }
    }
  }

  @import "custom/styles";
}
