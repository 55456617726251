@import "src/styles/functions";
@import "src/styles/variables/core/variables";
@import "src/styles/mixins";
@import "src/styles/dialogs/core/mixins";
@import "src/styles/dialogs/core/variables";

app-missions {
  @import "variables";

  m-ui-close-button {
    button {
      &.exit {
        &.normal {
          position: absolute;
          top: multiply-size(10px);
          right: multiply-size(-32px);
        }

        i {
          @include text-stroke();
          color: white;
        }
      }
    }
  }

  .navigation-bar {
    position: absolute;
    @include absolute-center-vertical;

    &.prev {
      left: multiply-size(-40px);
    }

    &.next {
      right: multiply-size(-40px);
    }
  }

  .global-dialog {
    width: multiply-size(510px);
    height: multiply-size(302px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .missions {
    display: flex;
    justify-content: center;
    padding-top: multiply-size(30px);
    padding-bottom: multiply-size(10px);

    .window-b {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      position: relative;
      padding: multiply-size(42px) 0 multiply-size(7px) 0;

      .new {
        width: multiply-size(40px);
        height: multiply-size(26px);
        position: absolute;
        top: multiply-size(10px);
        right: multiply-size(-29px);
        display: flex;
        @include right-overlap(
            (
              $background: $bgc-overlap,
            )
        );

        &:before {
          right: multiply-size(14px);
          width: multiply-size(15px);
        }

        span {
          @include mikado();
          font-weight: bold;
          font-size: multiply-size(10px);
          color: $color-B;
          margin-left: multiply-size(2px);
          text-transform: uppercase;
          transform: rotate(90deg);
          @include text-stroke;
        }
      }

      .check-completed {
        position: absolute;
        top: multiply-size(5px);
        right: multiply-size(5px);
        display: none;
        background-image: var(--asset--ui-icon-check-png);
        width: multiply-size(20px);
        height: multiply-size(20px);
        background-size: contain;
        background-repeat: no-repeat;
      }

      &.mission-completed {
        @include drop-shadow($color-B, 2, 3, 4);

        .check-completed {
          display: block;
        }
      }

      .title {
        text-align: center;
        @include mikado();
        font-weight: bold;
        font-size: multiply-size(10px);
        text-transform: uppercase;
        padding: 0 multiply-size(12px);
        line-height: multiply-size(12px);
        margin: 0;
      }

      .prizes {
        display: flex;

        & > * {
          margin: 0 multiply-size(6px);
        }
      }
    }

    .mission-icon {
      width: multiply-size(46px);
      height: multiply-size(46px);
      @include absolute-center-horizontal;
      top: multiply-size(-25px);

      img {
        width: 100%;
      }
    }
  }

  @import "custom/style";
}
