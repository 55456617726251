@import "src/styles/functions";
app-hud-bank-account-number {
  .ow-dialog {
    padding: multiply-size(40px) multiply-size(30px);

    .bottom-bar {
      padding: 0 multiply-size(28px);
      width: 100%;
      justify-content: space-between;

      .bar-button {
        margin: 0;

        .base {
          width: multiply-size(160px);
        }

        .red {
          width: multiply-size(126px);
        }

        button {
          width: inherit;
        }
      }
    }
  }

  .description {
    display: flex;
    font-size: multiply-size(12px);
    @include archivo-narrow();
  }
  .bank-account-label {
    display: flex;
    font-size: multiply-size(12px);
    @include archivo-narrow();
    margin-top: multiply-size(20px);
  }
  .bank-account-form > form {
    margin-top: multiply-size(6px);
    display: flex;
    align-items: center;
    .input-wrapper{
      position: relative;
      .iban-label{
        position: absolute;
        left: multiply-size(5px);
        top: multiply-size(5.1px);
        font-size: calc(12px* var(--multiply));
        font-family: "Archivo Narrow", sans-serif;
      }
      input {
        font-size: multiply-size(12px);
        @include archivo-narrow();
        border-radius: multiply-size(5px);
        border: solid black multiply-size(2px);
        width: multiply-size(200px);
        height: multiply-size(24px);
        text-align: left;
        padding-left: multiply-size(15px);
      }
    }

    button{
      font-size: multiply-size(12px);
      @include mikado();
      border-radius: multiply-size(5px);
      border: solid black multiply-size(2px);
      margin-left: multiply-size(10px);
      min-width: unset;
      width: multiply-size(100px) !important;
      height: multiply-size(28px) !important;
    }
  }
}
