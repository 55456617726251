@import "src/styles/functions";
@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-core-alert {
  .window-d {
    position: relative;
    // MAX SIZE DEFAULT IN ALERT SERVICE
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .close-icon {
      display: inline;
      position: absolute;
      top: multiply-size(3px);
      right: multiply-size(3px);
    }

    p {
      &.title {
        margin: 0 0 multiply-size(6px) 0;
        font-size: multiply-size(12px);
        @include mikado();
        font-weight: bold;
        @include text-stroke;
        color: $color-denary;
        text-align: center;
        text-transform: uppercase;
      }

      &.description {
        @include archivo-narrow;
        margin: 0;
        text-align: center;
        font-size: multiply-size(12px);

        ul,
        ol {
          padding-left: 0;
          margin-left: 1em;
        }
      }
    }

    m-ui-close-button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
