@import "src/styles/functions";
@import "src/styles/variables/core/variables";

#{$context-mobile} & {
}

#{$context-desktop} & {
  --multiply: 2;
}

#{$context-tablet} & {
  --multiply: 1.5;
}

.wrapper {
  width: 100%;
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  @include archivo-narrow();
}

.logo {
  background: var(--asset--ui-logo-png) center no-repeat;
  height: multiply-size(80px);
  width: multiply-size(140px);
  max-height: multiply-size(80px);
  max-width: multiply-size(140px);
  background-size: contain;
  display: flex;
  flex: 1 0 multiply-size(140px);
  margin: multiply-size(60px) 0 multiply-size(10px) 0;
}

.auth-button {
  width: multiply-size(200px);
  height: multiply-size(30px);
  padding: multiply-size(5px);
  border-radius: multiply-size(10px) 0 multiply-size(10px) 0;
  @include archivo-narrow();

  &.primary {
    font-size: multiply-size(14px);
    background: #00813D;

    &:hover, &:focus {
      filter: brightness(1.2);
    }
  }

  &.back-button {
    width: multiply-size(40px);
    height: multiply-size(40px);
    position: absolute;
    top: multiply-size(20px);
    left: multiply-size(20px);
  }

  a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.buttons-group {
  display: flex;
  flex-direction: column;
  gap: multiply-size(20px);
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: multiply-size(40px);
  max-width: multiply-size(262px);
  margin: 0 auto multiply-size(170px) auto;

  &.form {
    gap: multiply-size(20px);
  }
}

.footer-wrapper {
  padding-bottom: 30px;
}

.text-body {
  font-size: multiply-size(14px);
  line-height: multiply-size(16px);
  text-align: center;
  margin-bottom: multiply-size(4px);

  &.error {
    color: #f00;
    font-weight: 700;
  }
}
