@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-new-business-container {
  * {
    box-sizing: border-box;

  }
  .global-window{
      background-color: white;
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
    .component{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: unset;
      position: relative;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }

}
