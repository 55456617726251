@import "src/styles/functions";

app-hud-menu-more {
  m-ui-close-button {
    display: none;
  }

  .global-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }

  app-custom-hud-menu {
    width: 100%;

    .main-buttons-container {
      width: 100vw;
      height: 100vh;

      .more-button {
        height: multiply-size(40px);
        width: multiply-size(40px);
        position: absolute;
        top: multiply-size(236px);
        left: multiply-size(6px);
      }

      .view-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        .buttons-wrapper {
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
          justify-content: flex-start;
          height: multiply-size(180px);
          width: multiply-size(330px);
          margin: 0 auto;

          .main-button {
            height: multiply-size(70px);
            margin: multiply-size(10px) multiply-size(13px);
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;

            ow-object-svg {
              height: auto;
            }

            .button-name {
              margin-top: multiply-size(4px);
              @include archivo-narrow;
              font-size: multiply-size(10px);
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
