@import "src/styles/functions";
@import "src/styles/mixins";

hud-tabs {
  .hud-tabs {
    display: flex;
    transform-origin: left bottom;
    transform: rotate(-90deg);
    position: absolute;
    left: 0;
    bottom: multiply-size(16px);
    height: auto;

    .tab {
      height: multiply-size(24px);
      width: multiply-size(94px);
      background: #E8F3FF;
      border: multiply-size(2px) solid $color-K;
      color: $color-K;
      font-size: multiply-size(12px);
      @include mikado();
      margin-right: multiply-size(4px);
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &.active-tab {
        background: #FFFFFF;
        border-bottom: multiply-size(2px) solid #FFFFFF;
        cursor: initial;
      }
    }
  }
}
