@import "src/styles/functions";
@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-core-alert-image {
  .window-a {
    position: relative;
    // MAX SIZE DEFAULT IN ALERT SERVICE
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: multiply-size(15px);
    max-width: multiply-size(474px);
    max-height: multiply-size(276px);
    width: auto;
    height: auto;

    .close-icon {
      display: inline;
      position: absolute;
      top: multiply-size(3px);
      right: multiply-size(3px);
    }

    p {
      &.title {
        margin: 0 0 multiply-size(6px) 0;
        font-size: multiply-size(12px);
        @include mikado();
        font-weight: bold;
        @include text-stroke;
        color: $color-denary;
        text-align: center;
        text-transform: uppercase;
      }
    }

    m-ui-close-button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .pz-zoom-button {
    bottom: multiply-size(6px) !important;
    margin-left: multiply-size(-13px) !important;
    width: multiply-size(26px) !important;
    height: multiply-size(26px) !important;
    background-size: multiply-size(20px) !important;
  }
}
