@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-tasks-weekly {
  padding: multiply-size(16px);
  * {
    box-sizing: border-box;
  }

  .ow-dialog {
    &.primary {
      width: multiply-size(756px);
      height: multiply-size(345px);
      box-shadow: unset;
    }
  }

  .round-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: multiply-size(10px) 0;
    font-size: multiply-size(20px);
    line-height: multiply-size(12px);
    height: 100%;
    padding: 0 multiply-size(20px);

    &:hover {
      filter: brightness(1.2);
    }

    $colors: (
      "green": #00813d,
      "light-green": #019748,
      "lazure": #00a482,
    );

    p {
      @include archivo-narrow;
      font-size: multiply-size(14px);
      line-height: multiply-size(11px);
      margin: 0;
    }

    &.fixed {
      padding: 0;
      min-width: multiply-size(40px);
      min-height: multiply-size(40px);
    }

    &.round-reverse {
      border-radius: 0 multiply-size(10px);
    }

    i {
      text-shadow: none;
    }

    &.c {
      color: white;

      @each $key, $val in $colors {
        &-#{$key} {
          background-color: #{$val};
        }
      }
    }
  }
  .wrapper {
    width: calc(756px * var(--multiply));
    height: calc(345px * var(--multiply));
    position: relative;
    padding: calc(16px * var(--multiply));
    min-width: calc(700px * var(--multiply));
    app-business-navigation{
      .business-navigation{
        max-width: multiply-size(720px);
        //@media screen and (max-width: 700px) {
        //  min-width: unset;
        //}
      }
    }
    .content {
      width: multiply-size(498px);
      margin: auto;
      height: 100%;
      overflow: visible;
      * {
        @include archivo-narrow;
        color: black;
      }
      .arrow-pagination {
        @include font-awesome;
        position: absolute;
        font-size: multiply-size(20px);
        cursor: pointer;
      }
      .left {
        top: multiply-size(28px);
        left: multiply-size(94px);
      }
      .right {
        top: multiply-size(28px);
        right: multiply-size(94px);
      }
      .title {
        position: relative;
        border: solid 1px black;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        border-radius: multiply-size(10px) 0;
        height: multiply-size(40px);
        //margin-top: multiply-size(20px);
        img {
          width: multiply-size(90px);
          height: multiply-size(28px);
        }

        p {
          font-size: multiply-size(14px);
          font-weight: bold;
        }
      }
      ow-perfect-scrollbar {
        width: 100%;
        overflow: hidden;
        height: 100%;
        height: multiply-size(345px);
        .page {
          .task-name{
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: multiply-size(30px);
            p {
              margin-top: multiply-size(6px);
              font-size: multiply-size(14px);
              height: multiply-size(26px);
              margin-bottom: 0;
              padding: 0;
            }
          }
          .description {
            display: flex;
            flex-direction: column;
            min-height: multiply-size(74px);
            .subtitle {
              font-size: multiply-size(14px);
              line-height: multiply-size(12px);
              text-align: center;
              width: 100%;
              p {
                margin-top: multiply-size(6px);
                font-size: multiply-size(14px);
                height: multiply-size(26px);
                margin-bottom: 0;
                padding: 0;
              }
            }
            .time-period {
              font-size: multiply-size(14px);
              display: flex;
              text-align: center;
              align-items: center;
              justify-content: center;
              .date {
                padding: multiply-size(3px) 0 multiply-size(3px);
                height: multiply-size(20px);
                margin: 0;
              }
              .result {
                border-left: multiply-size(1px) solid black;
                padding: multiply-size(3px) 0 multiply-size(3px);
                height: multiply-size(20px);
                margin: 0;
                margin-left: multiply-size(16px);
                padding-left: multiply-size(16px);
              }
            }
          }
          .progress {
            position: relative;
            padding-top: multiply-size(13px);
            text-align: center;
            font-size: multiply-size(14px);
            p {
              margin: 0;
            }

            .bar {
              width: 100%;
              height: multiply-size(10px);
              border: multiply-size(1px) solid black;
              border-radius: multiply-size(2px);
              position: relative;
              overflow: hidden;
              margin-top: multiply-size(4px);

              .fill {
                top: 0;
                left: 0;
                height: 100%;
                position: absolute;
                background-color: #ff0909;
              }
            }

            .info {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: multiply-size(8px);

              div {
                display: flex;
                align-items: center;

                p {
                  margin: 0;
                  font-size: multiply-size(14px);
                  line-height: multiply-size(14px);
                  padding: multiply-size(2px) multiply-size(16px) multiply-size(2px) 0;
                  border-right: multiply-size(1px) solid black;

                  &:last-of-type {
                    border: none;
                    padding: multiply-size(2px) 0 multiply-size(2px) multiply-size(16px);
                  }
                }
              }
            }
            .table {
              display: flex;
              margin-left: multiply-size(20px);
              margin-right: multiply-size(20px);
              justify-content: space-around;
              margin-top: multiply-size(20px);
              min-height: multiply-size(84px);
              .plan {
                @include archivo-narrow;
                box-sizing: border-box;
                border-radius: multiply-size(10px) 0 multiply-size(10px) 0;
                background-color: black;
                color: white;
                display: flex;
                flex-direction: column;
                border: solid 1px black;
                width: multiply-size(64px);
                height: 100%;

                .execution {
                  border-radius: multiply-size(10px) 0 0 0;
                  margin: 0;
                  font-size: multiply-size(14px);
                  font-weight: bold;
                  padding: multiply-size(6px) multiply-size(9px);
                  text-align: center;
                  background-color: black;
                  width: 100%;
                  color: white;
                }

                .sale {
                  background-color: black;
                  color: black;
                  border: solid 1px black;
                  border-radius: multiply-size(10px) 0 multiply-size(10px) 0;

                  &.rounded-bottom {
                    border-radius: 0 0 multiply-size(10px) 0;
                  }

                  .percentage {
                    background-color: white;
                    text-align: center;
                    padding: multiply-size(2px);
                    margin: 0;
                    font-size: multiply-size(14px);
                    font-weight: bold;

                    &.rounded {
                      border-radius: multiply-size(10px) 0 0 0;
                    }
                  }
                  .prize {
                    background-color: white;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;

                    img {
                      height: multiply-size(14px);
                      width: multiply-size(14px);
                    }
                    p {
                      margin: 0;
                      padding: multiply-size(2px);
                      font-size: multiply-size(14px);
                    }
                    &.rounded-bottom {
                      border-radius: 0 0 multiply-size(10px) 0;
                    }
                  }
                  .cash {
                    border-radius: 0 0 multiply-size(10px) 0;
                  }
                }
                .fullfilment {
                  background-color: black;
                  border-radius: 0 0 multiply-size(10px) 0;
                  .text {
                    margin-top: multiply-size(6px);
                    text-align: center;
                    font-size: multiply-size(14px);
                    margin-bottom: 0;
                    color: white;
                  }
                  .number {
                    color: white;
                    margin-top: multiply-size(4px);
                    text-align: center;
                    font-size: multiply-size(14px);
                    font-weight: bold;
                    margin-bottom: multiply-size(6px);
                    border-radius: 0 0 multiply-size(10px) 0;
                  }
                }
              }
              &.narrow-columns {
                justify-content: center;
                gap: multiply-size(12px);
              }
            }
          }
          .total-prizes {
            font-size: multiply-size(14px);
            display: flex;
            flex: 1;
            text-align: center;
            align-items: center;
            justify-content: center;
            margin-top: multiply-size(20px);
            .target {
              padding: multiply-size(3px) 0 multiply-size(3px);
              height: multiply-size(20px);
              margin: 0;
              border-right: multiply-size(1px) solid black;
              padding-right: multiply-size(16px);
            }
            .amount {
              padding: multiply-size(3px) 0 multiply-size(3px);
              height: multiply-size(20px);
              margin: 0;
              padding-left: multiply-size(16px);
            }
          }
          .images {
            display: flex;
            justify-content: center;
            margin-top: multiply-size(10px);
            gap: multiply-size(12px);
            img {
              width: multiply-size(90px);
              height: multiply-size(28px);
            }
          }
        }
      }
    }
  }
  .faded {
    filter: brightness(1.2);
  }
}
