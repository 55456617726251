@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-user-settings {
  .container {
    flex-direction: column;
    height: 100%;
  }

  .window-a {
    padding: multiply-size(30px) multiply-size(38px) multiply-size(22px) multiply-size(38px);
  }

  .title {
    @include mikado();
    font-weight: bold;
    font-size: multiply-size(14px);
    text-transform: uppercase;
  }

  form {
    text-align: center;
  }

  input {
    margin: multiply-size(20px) 0 0 0;
    @include archivo-narrow();
    color: $color-K;
    border: multiply-size(2px) solid $color-K;
    height: multiply-size(32px);
    width: multiply-size(132px);
    font-size: multiply-size(12px);
    box-sizing: border-box;
    background: $color-I;
    text-align: center;
    border-radius: multiply-size(6px);
  }

  @import "custom/styles";
}
