app-ranking-details-game {
  @import "../../styles/styles";

  .position-info {
    font-weight: bold;
  }

  ow-perfect-scrollbar {
    .ps-content {
      padding-right: multiply-size(15px);
    }

    &.scroll-indicator {
      &:after {
        width: calc(100% - 2px * var(--multiply))
      }
    }
  }


  .ow-dialog {
    &.primary {
      .back-bar {
        &.gift-button {
          top: multiply-size(66px);
        }
      }

      .hall-results {
        top: multiply-size(208px);
      }

      &.level2{
        bottom: multiply-size(100px);
      }
    }
  }
  .filter-container {
    margin-top: multiply-size(10px);
    display: flex;
    align-items: center;
    border-top: multiply-size(2px) solid black;
    .each-filter {
      margin-top: multiply-size(10px);
      display: flex;
      align-items: center;
      margin-right: multiply-size(10px);

      button {
        margin-right: multiply-size(8px);
      }

      span {
        @include mikado;
        font-weight: bold;
        font-size: multiply-size(10px);
      }
    }
  }
}
