@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-hud-parameters {
  @import "../../../../base/core/components/hud-parameters/variables";

  .player-resources {
    display: flex;
    padding-top: multiply-size(4px);
    pointer-events: all;

    .bar {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      width: multiply-size(55px);
      min-width: multiply-size(55px);
      height: multiply-size(20px);
      justify-content: flex-end;
      padding-right: multiply-size(3px);
      @include mikado;
      font-size: multiply-size(10px);
      background-color: #358F42;
      font-weight: 700;
      color: $font-color;
      border-radius: multiply-size(5px);
      border: multiply-size(2px) solid #000000;
      margin: multiply-size(2px) multiply-size(10px) 0 multiply-size(10px);
      @include text-stroke;

      .icon-container {
        background-color: $color-I;
      }

      .resource-container {
        padding-right: multiply-size(4px);
      }

      .ow-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: multiply-size(25px);
        height: multiply-size(25px);
        position: absolute;
        left: 0;
        transform: translateX(-50%);
        background-size: contain;
      }

      .delta {
        position: absolute;
        right: multiply-size(-2px);
        bottom: multiply-size(-4px);
        display: flex;
        align-items: center !important;
        justify-content: flex-end;
        flex-direction: row-reverse;
        z-index: 1;

        span {
          transform: translateX(multiply-size(2px));
        }

        span {
          margin-right: multiply-size(1px);
          padding-top: multiply-size(3px);
          font-size: multiply-size(12px);
          margin-left: multiply-size(-2px);
        }

        &.green {
          left: multiply-size(-7px) !important;
          right: unset;
          bottom: multiply-size(-11px) !important;

          span {
            color: $color-B;
            @include text-stroke($color-H);
          }

          &.delta {
            align-items: baseline;
          }

          i {
            background-image: var(--asset--ui-arow-green-png);
            width: multiply-size(20px);
            height: multiply-size(20px);
            background-size: contain;
            background-repeat: no-repeat;
          }
        }

        &.red {
          left: multiply-size(-7px) !important;
          right: unset;
          bottom: multiply-size(-10px) !important;

          span {
            color: $color-G;
            @include text-stroke($color-H);
          }

          &.delta {
            align-items: center;
          }

          i {
            background-image: var(--asset--ui-arow-red-png);
            width: multiply-size(20px);
            height: multiply-size(20px);
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  .tooltip-container {
    font-weight: normal;
  }

  .fa-arrow-alt-up::before {
    display: none !important;
  }

  .fa-arrow-alt-down::before {
    display: none !important;
  }
}

