@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-prepaid-card-transactions-list {
  .ow-dialog {
    padding: multiply-size(25px) multiply-size(30px);

    .top-bar {
      .title {
        text-transform: none;
        @include mikado();
      }
    }
  }

  .content {
    .description {
      display: flex;
      align-items: center;
      @include archivo-narrow;
      margin-bottom: multiply-size(15px);
      font-size: multiply-size(10px);
      line-height: multiply-size(12px);
      height: multiply-size(20px);

      p {
        margin: 0;
      }

      .card-number {
        margin-left: multiply-size(4px);
        margin-right: multiply-size(10px);
      }

      .status {
        border-left: multiply-size(1px) solid #000;
        padding-left: multiply-size(10px);
        line-height: multiply-size(20px);
        height: 100%;

        &.active {
          color: #00813D;
        }

        &.inactive {
          color: #FF0909;
        }
      }
    }

    .list-wrapper {
      display: flex;
      flex-direction: column;
      @include archivo-narrow;
      text-align: left;
      font-weight: normal;
      width: 100%;
      border-collapse: collapse;
      height: multiply-size(190px);

      ow-perfect-scrollbar {
        width: calc(100% + 15px * var(--multiply)) !important;

        &.scroll-indicator {
          &:after {
            background: #000;
            height: multiply-size(1px);
          }
        }
      }

      .header,
      .transaction {
        display: flex;
        gap: multiply-size(12px);

        p {
          &:nth-child(1) {
            text-align: right;
            width: multiply-size(58px);
          }

          &:nth-child(2) {
            width: multiply-size(203px);
          }

          &:nth-child(3) {
            width: multiply-size(60px);
            text-align: center;
          }

          &:nth-child(4) {
            width: multiply-size(58px);
            text-align: center;

            &.payout {
              color: #00813D;
            }

            &.stored {
              color: #FF0909;
            }
          }
        }
      }

      .header {
        font-size: multiply-size(10px);
        line-height: multiply-size(12px);
        color: #7E7E7E;

        p {
          @include archivo-narrow;
          font-size: multiply-size(10px);
          line-height: multiply-size(12px);
          font-weight: normal;
          margin: 0;
          padding-bottom: multiply-size(5px);
        }
      }

      .body {
        display: flex;
        flex-direction: column;
        font-size: multiply-size(10px);
        line-height: multiply-size(12px);

        .transaction {
          display: flex;
          align-items: center;
          height: multiply-size(20px);

          &.bold {
            p {
              font-weight: bold;
            }

            &:not(:first-of-type) {
              height: multiply-size(19px);
              border-top: multiply-size(1px) solid #ECECEC;
            }
          }

          p {
            @include archivo-narrow;
            font-weight: normal;
            margin: 0;
          }
        }
      }
    }
  }
}
