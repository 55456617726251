@import "src/styles/functions";
&.short {
  width: multiply-size(62px);
  height: multiply-size(26px);
  text-transform: uppercase;
  @include mikado();
  font-weight: bold;
  font-size: multiply-size(10px);
  border: multiply-size(2px) solid;
  border-radius: multiply-size(8px);
}
