@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-custom-m-qa-list {
  $font-color: $color-H;

  .window-a {
    padding: multiply-size(30px) multiply-size(38px) multiply-size(22px) multiply-size(38px);
  }

  .prize-reduction {
    &.window-a {
      padding: multiply-size(20px) multiply-size(30px) multiply-size(22px) !important;
    }

    .wrapper {
      justify-content: flex-start !important;
    }

    .duration {
      margin-bottom: 0 !important;

      p {
        margin: 0 !important;
      }
    }

    .title {
      &.quiz {
        margin-bottom: multiply-size(4px) !important;
      }
    }

    .inner-html-description {
      height: multiply-size(24px) !important;
      margin-bottom: multiply-size(14px) !important;
    }
  }

  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .try-number {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: multiply-size(10px);
    @include mikado;
    @include text-stroke;
    font-size: multiply-size(10px);
    margin: 0;
  }

  .title {
    width: 100%;
    text-align: center;
    @include mikado;
    font-size: multiply-size(12px);
    color: $font-color;
    letter-spacing: 0;

    &.quiz {
      margin-bottom: multiply-size(15px);
    }

    &.margin-top {
      margin-top: multiply-size(90px);
    }
  }

  .inner-html-description {
    height: multiply-size(42px);
    font-size: multiply-size(10px);
    width: 100%;
    text-align: center;
    margin-bottom: multiply-size(15px);
    @include archivo-narrow;
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
  }

  .duration {
    margin-bottom: multiply-size(20px);
    @include mikado;
    font-size: multiply-size(12px);
    width: 100%;
    text-align: center;
    color: $font-color;
    letter-spacing: 0;
  }

  .icon-data {
    position: absolute;
    bottom: multiply-size(25px);
    right: multiply-size(57px);
  }

  .try-number {
    color: $font-color;
  }

  .inner-html-description {
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
