@import "src/styles/functions";
@import "../../../../base/core/components/hud/hud.component";

app-hud-custom {
  .top-left-container {
    position: relative;
    display: flex;
    margin-top: multiply-size(6px);
    left: multiply-size(3px);
    pointer-events: none;

    #{$context-desktop} & {
      --multiply: 2;
    }

    #{$context-tablet} & {
      --multiply: 1.5;
    }

    & > *:not(.hud-parameters) {
      pointer-events: all;
    }
    & > .hud-business{
      pointer-events: none;
    }
  }
  .top-right-container {
    position: relative;
    display: flex;
    margin-top: multiply-size(6px);
    right: multiply-size(3px);
    pointer-events: none;

    #{$context-desktop} & {
      --multiply: 2;
    }

    #{$context-tablet} & {
      --multiply: 1.5;
    }

    & > *:not(.hud-parameters) {
      pointer-events: all;
    }
  }
  .bottom-container {
    position: absolute;
    display: flex;
    margin-top: multiply-size(6px);
    bottom: multiply-size(13px);
    left: multiply-size(6px);
    #{$context-desktop} & {
      --multiply: 2;
    }

    #{$context-tablet} & {
      --multiply: 1.5;
    }
  }

  .hud-menu {
    position: absolute;
    top: multiply-size(52px);
    left: multiply-size(6px);
    z-index: 1;
    pointer-events: none;

    #{$context-desktop} & {
      --multiply: 2;
    }

    #{$context-tablet} & {
      --multiply: 1.5;
    }
  }

  .hud-business {
    pointer-events: none;
    position: absolute;
    right: 0;
    z-index: 1;
    height: 100dvh;
  }

  .hud-resources {
    pointer-events: all;
    padding: multiply-size(1px) multiply-size(4px) 0 0;
    z-index: 1;
    margin-left: multiply-size(14px);
    #{$context-desktop} & {
      --multiply: 2;
    }

    #{$context-tablet} & {
      --multiply: 1.5;
    }
  }

  .hud-parameters {
    pointer-events: all;
    padding: multiply-size(1px) multiply-size(4px) 0 0;
    z-index: 1;
    #{$context-desktop} & {
      --multiply: 2;
    }

    #{$context-tablet} & {
      --multiply: 1.5;
    }
  }

  .start-scene-container {
    position: fixed;
    z-index: 9;
    margin-left: 50%;
    display: none;
  }

  iframe {
    width: 1px !important;
    height: 1px !important;
  }
}
