app-ranking-game-details-info {
  @import "../../styles/styles";

  .window-a {
    padding: multiply-size(10px) multiply-size(19px) multiply-size(14px) multiply-size(29px) !important;
  }

  .desc {
    @include archivo-narrow();
    font-size: multiply-size(9px);
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    margin-bottom: multiply-size(9px);
    padding: 0 multiply-size(15px) 0 multiply-size(5px);
  }

  .results-table {
    height: multiply-size(162px);
    width: multiply-size(416px);

    ow-perfect-scrollbar {
      width: calc(100% + 6px * var(--multiply)) !important; // I had to override core styles

      &.scroll-indicator {
        &:after {
          width: calc(100% - 2px * var(--multiply))
        }
      }
    }

    table {
      tbody {
        tr {
          height: multiply-size(20px);
          border-bottom: 1px solid $color-H;
          box-sizing: border-box;

          td {
            @include archivo-narrow();

            &:first-child {
              width: multiply-size(32px) !important;
            }

            &:nth-child(2) {
              width: multiply-size(30px) !important;
            }

            &:nth-child(3) {
              width: multiply-size(314px) !important;
            }

            &:last-child {
              width: multiply-size(40px) !important;
            }
          }
        }
      }
    }
  }

  .details-info {
    display: flex;
    align-items: center;
    justify-content: center;
    @include archivo-narrow();
    font-size: multiply-size(10px);
    margin-top: multiply-size(14px);

    .rows {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      padding: 0 multiply-size(14px);

      &:first-child {
        border-right: multiply-size(1px) solid $color-H;

        .tr {
          .td.name {
            width: multiply-size(118px);
          }
        }
      }

      .tr {
        display: flex;
        align-items: flex-start;
        justify-content: center;

        .td {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          &.name {
            width: multiply-size(146px);
          }

          &.value {
            margin-left: multiply-size(8px);
            font-weight: bold;
            height: 100%;
          }
        }
      }
    }
  }
}
