@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

m-building-thumbnail {
  .building-thumbnail-container {
    width: multiply-size(50px);
    position: relative;
    padding-bottom: multiply-size(14px);

    img {
      width: 100%;
      object-fit: contain;
    }

    .level {
      white-space: nowrap;
      pointer-events: none;
      font-size: multiply-size(12px);
      @include mikado();
      font-weight: bold;
      bottom: 0;
      @include absolute-center-horizontal;
    }
  }
}
