.business-navigation {
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: multiply-size(16px);
  top: multiply-size(16px);
  width: calc(100% - 32px);
  height: calc(100% - 64px);
  min-width: multiply-size(665px);
  max-height: calc(100vh - 100px);

  .month-selector {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: multiply-size(10px) 0;
    font-size: multiply-size(20px);
    line-height: multiply-size(12px);
    height: 100%;

    $colors:(
      'green': #00813D,
      'light-green': #019748,
      'lazure': #00A482,
    );

    .month{
      display: flex;
      align-content: center;
      justify-content: center;
      height: 100%;

      p {
        @include archivo-narrow;
        font-size: multiply-size(12px);
        line-height: multiply-size(11px);
        height: 100%;
        text-align: center;
        padding-left: multiply-size(5px);
        padding-right: multiply-size(5px);
        min-width: multiply-size(62px);
      }
    }

    &.fixed {
      padding: 0;
      min-width: multiply-size(40px);
      min-height: multiply-size(40px);
    }

    i {
      text-shadow: none;
    }

    .c {
      @each $key, $val in $colors {
        &-#{$key} {
          color: white;
          background-color: #{$val};
        }
      }
    }
    .arrow{
      @include font-awesome;
    }
    button{
      z-index: 10;
      height: 100%;
      width: multiply-size(40px);
      align-content: center;
      justify-content: center;

      &:hover {
        filter: brightness(1.2);
      }

      &.arrow-previous{
        border-radius:multiply-size(10px) 0 0 0 ;
      }
      &.arrow-next{
        border-radius: 0 0 multiply-size(10px) 0 ;
      }
    }

  }

  .left,
  .right {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
  }

  .left {
    width: multiply-size(36px);

    .top {
      display: flex;
      gap: multiply-size(8px);
      flex-direction: column;

      &.reverse {
        margin-top: 100%;
        flex-direction: row-reverse;
        transform: rotate(270deg);
        height: multiply-size(36px);
        transform-origin: top left;
      }
    }
  }

  .right {
    .top,.bottom {
      display: flex;
      flex-direction: column;
      gap: multiply-size(8px);
    }
  }
}

.business-button-round {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: multiply-size(10px) 0;
  font-size: multiply-size(20px);
  line-height: multiply-size(12px);
  height: 100%;
  padding: 0 multiply-size(7px);

  $colors:(
    'green': #00813D,
    'light-green': #019748,
    'lazure': #00A482
  );

  span.message-count {
    position: absolute;
    top: multiply-size(2px);
    right: multiply-size(2px);
    font-size: multiply-size(9px);
    color: #00813d;
    background: #fff;
    width: multiply-size(13px);
    height: multiply-size(13px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 700;
  }

  p {
    @include archivo-narrow;
    font-size: multiply-size(12px);
    line-height: multiply-size(11px);
    margin: 0;
    color: white;
  }

  i {
    text-shadow: none;
  }

  &.fixed {
    padding: 0;
    min-width: multiply-size(40px);
    min-height: multiply-size(40px);
  }

  &.blocked {
    opacity: 0.2;

    &:hover {
      filter: unset;
    }
  }

  &.c {
    color: white;

    @each $key, $val in $colors {
      &-#{$key} {
        background-color: #{$val};
      }
    }
  }
}
