@import "../variables";
@import "../mixins";

$colors: (
  $font-stroke: #003366,
  $border: #003366,
  $background: (
    $normal: #6699cc,
    $active: #336699,
  ),
  $box-shadow: (
    $normal: #336699,
    $active: #003366,
  ),
  $font: (
    $normal: #ffffff,
    $active: #a4c0db,
  ),
);

&.primary {
  @include generate-schema-button-color($colors);
}
