@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

loading {
  .is-loading &,
  &.is-loading {
    .loading-content {
      display: block;
    }
  }

  .ow-dialog {
    .loading-content {
      top: multiply-size(-20px);
      left: multiply-size(-20px);
      bottom: multiply-size(-20px);
      right: multiply-size(-20px);
    }
  }

  .loading-content {
    z-index: 30;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: inherit;
    display: none;

    .relative {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: multiply-size(30px);

      i {
        position: initial;
      }
    }

    &.white {
      color: #ffffff;
    }

    &.transparent {
      color: transparent;
    }

    &.big {
      .relative {
        font-size: multiply-size(25px);
      }
    }

    &.small {
      .relative {
        font-size: multiply-size(15px);
      }
    }

    &.relative {
      position: relative;
    }

    &.stroke {
      @include text-stroke;
    }
  }
}
