@import "src/styles/functions";
@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-message-details-normal {
  @import "../../../core/styles/style";
  @import "variables";

  .message-info {
    display: flex;
    align-items: center;
    min-height: multiply-size(25px);

    .sender-image {
      width: multiply-size(25px);
      height: multiply-size(25px);
      margin-right: multiply-size(6px);

      img {
        width: 100%;
        object-fit: contain;
      }
    }

    .message-details {
      overflow: hidden;
      line-height: multiply-size(14px);

      .sender-and-date,
      .message-title {
        margin: 0;
      }

      .sender-and-date {
        font-size: multiply-size(10px);
        @include archivo-narrow;
      }

      .message-title {
        @include text-ellipsis;
        @include myriad;
        font-size: multiply-size(12px);
        font-weight: bold;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;

    & > * {
      margin: multiply-size(20px) multiply-size(7px);
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  table {
    margin-top: multiply-size(20px);
    width: 100%;

    td:first-child {
      width: 14%;
    }

    td:last-child {
      width: auto;
    }
  }

  @import "custom/style";
}
