@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-hud-player-avatar {
  position: relative;
  background: var(--asset--ui-bar-player-png) no-repeat;
  background-size: contain;
  display: block;
  width: multiply-size(60px);
  height: multiply-size(60px);
  background: none;
  margin: multiply-size(35px) 0 multiply-size(8px) 0;

  .player-bar-container {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;

    m-ui-game-date {
      z-index: 3;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
    }

    .avatar,
    .user-options {
      @include absolute-center;
      border-radius: 50%;
    }

    .avatar {
      z-index: -1;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;

      &.default {
        background-image: var(--asset--ui-avatar-temp-png);
      }
    }

    .user-options {
      opacity: 0;
      transition: 0.4s;
      background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.79) 0%, rgba(88, 130, 162, 0) 70%, rgba(125, 185, 232, 0) 100%);
      z-index: 5;
      color: $color-I;
      cursor: pointer;
      font-size: multiply-size(10px);

      i {
        opacity: 0.9;
      }

      &:hover {
        opacity: 1;
      }

      input {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        overflow: hidden;
        cursor: pointer;
        opacity: 0;
      }
    }
  }
}
