@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-challenges-details {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  * {
    box-sizing: border-box;
  }

  .ow-dialog {
    &.primary {
      width: multiply-size(756px);
      height: multiply-size(345px);
      box-shadow: unset;
      overflow: auto;
    }
  }

  ow-perfect-scrollbar {
    width: calc(100% + 15px * var(--multiply)) !important;
    height: multiply-size(264px);
    max-height: multiply-size(244px);

    @media screen and (max-width: 1080px) {
      max-height: calc(100vh - 170px);
      width: calc(100% + 12px * var(--multiply)) !important;
    }
  }

  .wrapper {
    width: 100%;
    height: 100%;
    padding: multiply-size(16px);
    min-width: multiply-size(700px);
    font-size: multiply-size(14px);
    //@media screen and (max-width: 700px) {
    //  min-width: unset;
    //}
    //
    app-business-navigation{
      .business-navigation{
        max-width: multiply-size(720px);
        //@media screen and (max-width: 700px) {
        //  min-width: unset;
        //}
      }
    }

    .content {
      //width: calc(558px * var(--multiply));
      padding: 0 multiply-size(30px);
      width: multiply-size(558px);
      margin: auto;
      height: 100%;
      //overflow: hidden;

      * {
        @include archivo-narrow;
      }

      .swiper-navigation {
        position: relative;

        * {
          @include font-awesome;
        }
        .arrow-pagination{
          position: absolute;
          font-size: multiply-size(20px);
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: multiply-size(40px);
          height: multiply-size(40px);

          &:hover {
            opacity: 0.8;
          }
        }

        .left{
          top: 0;
          left: multiply-size(-40px);
        }

        .right {
          top: 0;
          right: multiply-size(-40px);
        }
      }

      m-ui-currency {
        .stock-j {
          margin-bottom: 0;

          .value {
            text-shadow: unset;
            color: black;
            font-size: multiply-size(14px);
            font-weight: normal;
          }

          .icon {
            margin-right: 0;
          }
        }
      }

      .page {
        height: multiply-size(250px);
        overflow: unset;

        &.vote-mode {
          .progress,
          .strategy,
          .description {
            opacity: 0;
          }

          .progress {
            overflow: hidden;
            height: multiply-size(38px);
          }

          .hint {
            height: 0;
          }

          .confirm-title {
            opacity: 1;
          }

          .confirm-boxes {
            opacity: 1;
            z-index: 10;
          }

          .ps-content {
            padding-right: multiply-size(15px);
          }
        }
      }

      // animating fadeout
      .progress,
      .strategy,
      .description {
        transition: opacity 0.2s ease-in-out;
      }

      .progress {
        transition: height 0.2s ease-in;
      }

      .hint {
        transition: height 0.2s ease-in-out;
      }

      .title-container {
        border: solid 1px black;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        border-radius: multiply-size(10px) 0;
        height: multiply-size(40px);

        position: relative;

        img {
          width: multiply-size(90px);
          height: multiply-size(28px);
          object-fit: contain;
        }

        p {
          font-size: multiply-size(14px);
          font-weight: bold;
        }


      }
      .badge {
        @include archivo-narrow;
        display: flex;
        justify-content: center;
        width: multiply-size(208px);
        height: multiply-size(20px);
        font-size: multiply-size(14px);
        line-height: multiply-size(12px);
        border-radius: 0 0 multiply-size(10px) multiply-size(10px);
        padding: 0 multiply-size(24px);
        color: white;
        text-align: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: multiply-size(8px);
        p {
          margin: 0;
          padding: 0;
          font-size: multiply-size(12px);
          line-height: inherit;
          font-weight: normal;
        }

        &.red {
          background-color: #ff0909;
        }

        &.yellow {
          background-color: #ff9100;
        }

        &.green {
          background-color: #00813d;
        }
      }
      .activity-name {
        font-size: multiply-size(14px);
        line-height: multiply-size(12px);
        margin-bottom: multiply-size(10px);

        text-align: center;
        width: multiply-size(498px);
      }

      .decorator {
        position: relative;
        margin: multiply-size(14px) 0;
        background-color: black;
        height: multiply-size(1px);

        p {
          font-size: multiply-size(12px);
          line-height: multiply-size(12px);
          font-weight: bold;
          position: absolute;
          top: multiply-size(-6px);
          margin: 0;
          background-color: white;
          padding-right: multiply-size(6px);
        }
      }

      .progress {
        @include archivo-narrow;
        position: relative;
        margin-bottom: multiply-size(10px);
        padding-top: multiply-size(13px);
        height: multiply-size(49px);

        .percentage {
          position: absolute;
          margin: 0;
          top: 0;
          font-size: multiply-size(14px);
          line-height: multiply-size(12px);
          margin-bottom: multiply-size(2px);
          transition: left 0.2s ease-in-out;
        }

        .bar {
          width: 100%;
          height: multiply-size(8px);
          border: multiply-size(1px) solid black;
          border-radius: multiply-size(2px);
          position: relative;
          overflow: hidden;
          margin: 0 0 multiply-size(8px);

          .fill {
            top: 0;
            left: 0;
            height: 100%;
            position: absolute;
            background-color: #FF0909;
            transition: width 0.2s ease-in-out;
          }
        }

        .info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: multiply-size(12px);
          height: multiply-size(20px);

          .left, .right {
            display: flex;
            align-items: center;
            height: 100%;

            p {
              margin: 0;
              font-size: multiply-size(14px);
              line-height: multiply-size(16px);
              padding: multiply-size(2px) multiply-size(16px) multiply-size(2px) 0;

              &:not(:only-child) {
                border-right: multiply-size(1px) solid black;
              }

              &:last-of-type:not(:only-child) {
                border: none;
                padding: multiply-size(2px) 0 multiply-size(2px) multiply-size(16px);
              }
            }
          }

          .left {
            p:first-of-type {
              padding-left: 0;
            }
          }
        }
      }

      .difficulty {
        margin-top: multiply-size(10px);
        margin-bottom: multiply-size(26px);
        position: relative;

        .hint {
          font-size: multiply-size(12px);
          line-height: multiply-size(12px);
          margin-bottom: multiply-size(20px);
          text-align: center;
          width: 100%;
          height: multiply-size(24px);
          overflow: hidden;
        }

        .vote {
          font-size: multiply-size(14px);
          text-align: right;
          position: absolute;
          right: 0;
          bottom: multiply-size(-29px);

          span {
            &.red {
              color: #FF0909;
            }

            &.yellow {
              color: #FFCC00;
            }

            &.green {
              color: #00813D;
            }
          }
        }

        .confirm-title {
          margin: 0;
          position: absolute;
          top: multiply-size(-24px);
          font-size: multiply-size(14px);
          font-weight: bold;
          opacity: 0;
          transition: opacity 0.1s ease-in;
        }

        .confirm-boxes {
          display: flex;
          justify-content: center;
          opacity: 0;
          transition: opacity 0.1s ease-in;
          position: absolute;
          top: multiply-size(-33px);
          right: 0;
          gap: multiply-size(10px);
          width: auto;
          //bottom: inherit;
          //margin-top: 100px;
          z-index: 0;

          .business-button-round {
            @include archivo-narrow;
            height: multiply-size(40px);
            width: multiply-size(80px);
            font-size: multiply-size(14px);
            color: white;
            cursor: pointer;

            &:hover {
              opacity: 0.8;
            }

            &:disabled {
              opacity: 0.2;
              cursor: not-allowed;
            }
          }
        }

        .boxes-container {
          display: flex;
          justify-content: space-between;
          min-height: multiply-size(94px);

          .box {
            width: multiply-size(156px);
            height: multiply-size(94px);
            border: multiply-size(1px) solid black;
            padding-top: multiply-size(3px);
            transition: opacity 0.05s ease-in;

            &.red {
              background-color: #FF0909;
            }

            &.yellow {
              background-color: #FFCC00;
            }

            &.green {
              background-color: #00813D;
            }

            &.votable {
              cursor: pointer;

              &:hover {
                box-shadow: rgba(0, 0, 0, 0.05) 0 multiply-size(18px) multiply-size(42px) 0, rgba(0, 0, 0, 0.08) 0 0 0 multiply-size(1px);
              }
            }

            &.fade-out {
              opacity: 0.2;
            }

            .info {
              height: multiply-size(84px);
              background-color: white;
              padding: multiply-size(4px) multiply-size(6px);

              .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: multiply-size(8px);
                border-bottom: multiply-size(1px) solid black;

                .name {
                  font-size: multiply-size(14px);
                  line-height: multiply-size(12px);
                  font-weight: bold;
                  margin: 0;
                  text-transform: capitalize;

                  .green {
                    color: #1F6E2B;
                  }
                }

                .votes {
                  display: flex;
                  align-items: center;
                  font-size: multiply-size(14px);
                  line-height: multiply-size(12px);

                  .round-button {
                    width: multiply-size(16px);
                    height: multiply-size(16px);
                    border-radius: 0 multiply-size(4px);
                    padding: 0;
                    margin-left: multiply-size(6px);
                    color: white;
                  }
                }
              }
              .award {
                display: flex;
                justify-content: space-between;
                padding-top: multiply-size(4px);

                .objective {
                  display: flex;
                  flex-direction: column;
                  .name {
                    font-size: multiply-size(14px);
                    line-height: multiply-size(12px);
                    margin: 0;
                    margin-top: multiply-size(2px);
                  }

                  .value {
                    font-size: multiply-size(20px);
                    line-height: multiply-size(12px);
                    margin: 0;
                    font-weight: bold;
                    padding-top: multiply-size(2px);
                    margin-top: multiply-size(4px);
                  }
                }

                .bonus {
                  display: flex;
                  align-items: baseline;

                  p {
                    font-size: multiply-size(14px);
                    line-height: multiply-size(12px);
                    margin: 0 multiply-size(2px) 0 0;
                  }

                  .list {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    //width: multiply-size(36px);
                    gap: multiply-size(4px);

                    div {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;

                      img {
                        margin-left: multiply-size(2px);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .strategy {
        min-height: multiply-size(65px);

        .thresholds {
          display: flex;
          align-items: flex-start;
          justify-content: center;

          .step {
            display: flex;
            align-items: flex-start;
            padding-right: multiply-size(14px);
            padding-left: multiply-size(8px);
            position: relative;
            height: multiply-size(50px);

            &:not(:last-of-type) {
              &:after {
                content: '';
                width: multiply-size(1px);
                height: multiply-size(32px);
                background-color: black;
                position: absolute;
                right: 0;
              }
            }

            &:first-of-type {
              padding-left: 0;
            }

            &:last-of-type {
              border-right: unset;
              padding-right: unset;
            }

            .name {
              font-size: multiply-size(14px);
              line-height: multiply-size(12px);
              font-weight: bold;
              padding-top: multiply-size(1px);
              margin: 0 multiply-size(11px) 0 0;
            }

            .list {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              width: multiply-size(36px);
              gap: multiply-size(4px);

              div {
                display: flex;
                align-items: center;
                justify-content: space-between;

                p {
                  font-size: multiply-size(14px);
                  line-height: multiply-size(12px);
                  margin: 0 multiply-size(2px) 0;
                }
              }
            }
          }
        }
      }

      .description {
        p {
          margin: 0;
          font-size: multiply-size(14px);
          line-height: multiply-size(12px);
          text-align: justify;
        }
        table {
          border-collapse: collapse;
        }

        th, td {
          text-align: center; /* Align text to the left in table headers and cells */
          padding: 8px; /* Add some padding inside the table cells for better readability */
          border: 1px solid black; /* 1px solid black border for each cell */
        }
      }
    }
  }
}
