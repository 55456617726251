@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-plan-shop-list {
  * {
    box-sizing: border-box;
  }

  .ow-dialog {
    &.primary {
      width: multiply-size(756px);
      height: multiply-size(345px);
    }
  }

  .round-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: multiply-size(10px) 0;
    font-size: multiply-size(20px);
    line-height: multiply-size(12px);
    height: 100%;
    padding: 0 multiply-size(20px);

    &:hover {
      filter: brightness(1.2);
    }


    $colors: (
            'green': #00813D,
            'light-green': #019748,
            'lazure': #00A482,
    );

    p {
      @include archivo-narrow;
      font-size: multiply-size(12px);
      line-height: multiply-size(11px);
      margin: 0;
    }

    &.fixed {
      padding: 0;
      min-width: multiply-size(40px);
      min-height: multiply-size(40px);
    }

    &.round-reverse {
      border-radius: 0 multiply-size(10px);
    }

    i {
      text-shadow: none;
    }

    &.c {
      color: white;

      @each $key, $val in $colors {
        &-#{$key} {
          background-color: #{$val};
        }
      }
    }
  }

  .wrapper {
    width: 100%;
    height: 100%;
    padding: multiply-size(20px);

    nav {
      display: flex;
      justify-content: space-between;
      position: absolute;
      left: multiply-size(16px);
      top: multiply-size(16px);
      width: calc(100% - 64px);
      height: calc(100% - 64px);

      .left,
      .right {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
      }

      .left {
        width: multiply-size(36px);

        .top {
          display: flex;
          gap: multiply-size(8px);
          flex-direction: row-reverse;
          height: multiply-size(36px);
        }
      }

      .right {
        .top, .bottom {
          display: flex;
          flex-direction: column;
          gap: multiply-size(8px);
        }
      }
    }

    .content {
      width: multiply-size(498px);
      margin: auto;
      height: 100%;
      overflow: visible;

      * {
        @include archivo-narrow;
      }

      //.swiper-navigation {
      //  position: sticky;
      //  width: 100%;
      //  * {
      //
      //  }
        .arrow-pagination{
          @include font-awesome;
          position: absolute;
          font-size: multiply-size(20px);
          cursor: pointer;
        }
        .left{
          top:multiply-size(28px);
          left: multiply-size(94px);
        }
        .right {
          top:multiply-size(28px);
          right: multiply-size(94px);
        }
      //}

      .plan{
        position: relative;
        border: solid 1px black;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        border-radius: multiply-size(10px) 0;
        height: multiply-size(40px);
        img{
          width: multiply-size(90px);
          height: multiply-size(28px);
        }
        //.hint {
        //  font-size: multiply-size(12px);
        //  line-height: multiply-size(12px);
        //  margin: multiply-size(4px) 0 multiply-size(8px);
        //  text-align: center;
        //  width: 100%;
        //}
      }

      .task{
        font-size: multiply-size(12px);
        line-height: multiply-size(12px);
        margin-top: multiply-size(6px);
        text-align: center;
        width: 100%;
      }

      .decorator {
        margin-bottom: multiply-size(5px);
        position: relative;
        background-color: black;
        height: multiply-size(1px);

        p {
          font-size: multiply-size(12px);
          line-height: multiply-size(12px);
          font-weight: bold;
          position: absolute;
          top: multiply-size(-6px);
          margin: 0;
          background-color: white;
          padding-right: multiply-size(6px);
        }
      }

      .progress {
        position: relative;
        padding-top: multiply-size(13px);

        .percentage {
          position: absolute;
          left: calc(50% - 7px);
          margin: 0;
          top: 0;
          font-size: multiply-size(12px);
          line-height: multiply-size(12px);
        }

        .bar {
          width: 100%;
          height: multiply-size(8px);
          border: multiply-size(1px) solid black;
          border-radius: multiply-size(2px);
          position: relative;
          overflow: hidden;
          margin: 0 0 multiply-size(8px);

          .fill {
            top: 0;
            left: 0;
            height: 100%;
            position: absolute;
            background-color: #FF0909;
          }
        }

        .info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: multiply-size(8px);

          div {
            display: flex;
            align-items: center;

            p {
              margin: 0;
              font-size: multiply-size(12px);
              line-height: multiply-size(12px);
              padding: multiply-size(2px) multiply-size(16px) multiply-size(2px) 0;
              border-right: multiply-size(1px) solid black;

              &:last-of-type {
                border: none;
                padding: multiply-size(2px) 0 multiply-size(2px) multiply-size(16px);
              }
            }
          }
        }
      }

      .search{
        margin-top: multiply-size(15px);
        position: relative;
        .search-bar{
          input {
            text-align: left;
            border: solid multiply-size(1px) black;
            border-radius: multiply-size(10px) 0;
            width: multiply-size(198px);
          }
          .fa-search{
            @include font-awesome;
            position: relative;
            right: multiply-size(22px);
            font-size: multiply-size(15px);
            top: multiply-size(1px)

          }
        }

      }

      .table{
        width: 100%;
        margin: auto;
        overflow: hidden;
        height: 100%;
        ow-perfect-scrollbar{
          height:multiply-size(125px);
        }
        .table-headers{
          position: relative;
          margin-top: multiply-size(18px);
          height: multiply-size(14px);

          button{
            color: black;
            position: absolute;
            height: multiply-size(14px);
            font-size: multiply-size(12px);
            i{
              margin-left: multiply-size(6px);
              @include font-awesome;
              width: multiply-size(10px);
              text-shadow: none;
            }
          }
          .header-name{
            left: 0px;
          }
          .header-id{
            right: multiply-size(86px);
          }
          .header-realization{
            right: 0px;
          }
        }
        .mat-mdc-table {
          margin-top: multiply-size(10px);
          width: 100%;
          border-spacing: 0px;
          box-shadow: unset;
        }
        .mat-column-name {
          width: multiply-size(331px);
        }
        .mat-column-numberID {
          text-align: center;
        }
        .mat-column-realization {
          text-align: center;
        }
        .mat-mdc-row{
          height: multiply-size(20px);
        }
        .mat-mdc-cell{
          color: black;
          font-size: multiply-size(12px);
          border-bottom: multiply-size(1.5px) solid #000;
        }
        .no-data-match{
          text-align: center;
          font-size: 24px;
        }
      }

    }
  }

  .badge {
    @include archivo-narrow;
    width: multiply-size(80px);
    height: multiply-size(12px);
    font-size: multiply-size(9px);
    line-height: multiply-size(12px);
    border-radius: 0 0 0 multiply-size(10px);
    color: white;
    position: absolute;
    text-align: center;
    top: 0;
    right: 0;

    &.red {
      background-color: #FF0909;
    }

    &.yellow {
      background-color: #FF9100;
    }

    &.green {
      background-color: #00813D;
    }
  }

}
