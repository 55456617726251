@import "src/styles/variables/core/variables";
@import "src/styles/functions";
@import "src/styles/mixins";

app-landing {
  #{$context-mobile} & {
  }

  #{$context-desktop} & {
    --multiply: 2;
  }

  #{$context-tablet} & {
    --multiply: 1.5;
  }

  background: #F8F8F8;

  .background-wrapper {
    background: url("../../../../../../../assets/other/landing/background.png") no-repeat center top;
    margin: 0 auto;
    max-width: multiply-size(350px);
    height: multiply-size(600px);
    display: flex;
    @include gotham();
    font-weight: 700;
    text-align: center;
    flex-direction: column;
    background-size: cover;

    .title {
      font-size: multiply-size(14px);
      line-height: multiply-size(17px);
      margin-top: multiply-size(145px);
      text-align: center;
    }

    .cta-button {
      width: multiply-size(227px);
      height: multiply-size(57px);
      background: url("../../../../../../../assets/other/landing/button.png") no-repeat center top;
      background-size: contain;
      display: flex;
      margin: multiply-size(32px) auto 0;
    }
  }
  .description-wrapper {
    margin: multiply-size(25px) auto 0;
    padding-bottom: multiply-size(50px);
    max-width: multiply-size(350px);

    .title {
      font-size: multiply-size(14px);
      line-height: multiply-size(17px);
      text-align: center;
      @include gotham();
      font-weight: 700;
    }

    .divider {
      height: multiply-size(1px);
      width: 100%;
      background: #000;
      margin: multiply-size(25px) 0;
    }
    .content {
      @include archivo-narrow();
      font-size: multiply-size(10px);
      line-height: multiply-size(13px);

      .highlighed {
        font-weight: 700;
      }
    }

    a {
      text-decoration: none;
    }
  }
}
