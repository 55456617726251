@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

m-building-upgrade-diff-production {
  .upgrade-container {
    display: flex;
  }

  .production-container {
    height: multiply-size(12px);
    top: multiply-size(14px);
    right: multiply-size(12px);

    &.arrow {
      width: multiply-size(23px);

      &:before {
        left: multiply-size(-17px);
      }
    }
  }

  .upgrade {
    position: relative;

    m-ui-stock-other,
    m-ui-currency,
    m-ui-product,
    m-ui-parameter {
      &.quantity-diff {
        .value {
          color: $color-B;
          @include text-stroke($color-H);
        }
      }
    }

    .new-indicator {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;

      .exclamation-new {
        position: absolute;
        top: 0;
        right: 0;
        color: $color-B;
        @include text-stroke;
      }

      .new {
        position: absolute;
        bottom: 0;
        right: 0;
        color: $color-B;
        font-size: multiply-size(10px);
        @include text-stroke;
        @include mikado();
        font-weight: bold;
      }
    }
  }

  @import "custom/style";
}
