
.mobile {
  .tooltip {
    .tooltip-inner {
      white-space: initial;
      max-width: none;
      font-size: multiply-size(8px);
    }
  }
}
