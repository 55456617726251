@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-custom-m-qa-details {
  .ow-dialog {
    &.window-a {
      padding: multiply-size(15px) multiply-size(30px) multiply-size(24px) multiply-size(30px);
    }
  }

  .qa-container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .title {
    width: 100%;
    text-align: center;
    @include text-stroke;
    @include mikado;
    font-size: multiply-size(14px);
    margin-bottom: multiply-size(15px);
    color: $color-I;
    max-height: multiply-size(18px);
    overflow: hidden;
  }

  .wrap {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
  }

  .description {
    font-size: multiply-size(13px);
    margin-bottom: multiply-size(5px);
    margin-left: multiply-size(5px);
    @include archivo-narrow;
    padding-right: multiply-size(10px);
    box-sizing: border-box;
  }

  .elem-flex {
    display: inline-flex;
    position: relative;
    width: 100%;
    text-align: left;
    align-items: center;

    span {
      padding-top: multiply-size(2px);
    }

    p {
      margin: 0;
    }

    .image-url {
      margin: 0 multiply-size(10px);
      min-width: multiply-size(26px);
      min-height: multiply-size(26px);

      i {
        font-size: multiply-size(10px);
      }
    }
  }

  .image-container {
    display: flex;
    justify-content: flex-end;
  }

  .image-explanation-container {
    margin: multiply-size(5px) 0 multiply-size(10px) multiply-size(40px);
    display: flex;

    button {
      margin-right: multiply-size(8px);
    }
  }

  .question {
    margin-bottom: multiply-size(4px);
    font-size: multiply-size(11px);
    min-height: multiply-size(40px);
    @include archivo-narrow;

    button {
      &.checkbox {
        min-width: multiply-size(32px);
        min-height: multiply-size(32px);
        margin-right: multiply-size(10px);
        background-color: #ebf3fd;
      }

      i {
        font-size: multiply-size(16px);

        &.fa-circle {
          color: $color-K;
          text-shadow: none;
        }

        &.fa-check {
          &.all-correct {
            color: $color-K;
            text-shadow: none;
          }

          &.selected-correct {
            color: $color-S;
          }
        }

        &.fa-times {
          color: $color-G;
        }
      }
    }
  }

  .question-tag {
    @include text-stroke;
    @include mikado;
    font-size: multiply-size(14px);
    color: $color-I;
    margin-right: multiply-size(10px);
    padding-left: multiply-size(1px);
  }

  .md-form {
    height: multiply-size(150px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @include archivo-narrow;

    .md-textarea {
      width: 88%;
      height: 88%;
      padding: multiply-size(5px);
      border-radius: multiply-size(7px);
      border: multiply-size(1px) solid $color-K;
      font-size: multiply-size(12px);
      background-color: #fbfbfb;

      &:focus {
        background-color: #f2f4f4 !important;
      }
    }

    button {
      width: multiply-size(66px);
      height: multiply-size(20px);
      background-color: white;
      position: absolute;
      bottom: multiply-size(12px);
      border: multiply-size(1px) solid #036;
      font-size: multiply-size(10px);
      border-radius: multiply-size(2px);
    }
  }

  .navigation-container {
    position: absolute;
    top: 50%;

    &.prev {
      left: 0;
    }

    &.next {
      right: 0;
    }
  }

  .correct {
    color: #53aa22 !important;
    font-weight: bold;
  }

  .scroll-indicator:after {
    background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 51%, rgba(255, 255, 255, 0) 100%);
  }
}
