ranking-game-table {
  @import "../../../styles/styles";

  .display-none {
    display: none !important;
  }

  .high-light {
    color: $color-M;
    background-color: #E0E4D8;
  }
}
