@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-client-list {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  * {
    box-sizing: border-box;
  }

  .ow-dialog {
    &.primary {
      width: multiply-size(756px);
      height: multiply-size(345px);
      box-shadow: unset;
      overflow: auto;
    }
  }

  .round-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: multiply-size(10px) 0;
    font-size: multiply-size(20px);
    line-height: multiply-size(12px);
    height: 100%;
    padding: 0 multiply-size(20px);

    &:hover {
      filter: brightness(1.2);
    }

    $colors: (
      "green": #00813d,
      "light-green": #019748,
      "lazure": #00a482,
    );

    p {
      @include archivo-narrow;
      font-size: multiply-size(12px);
      line-height: multiply-size(11px);
      margin: 0;
    }

    &.fixed {
      padding: 0;
      min-width: multiply-size(40px);
      min-height: multiply-size(40px);
    }

    &.round-reverse {
      border-radius: 0 multiply-size(10px);
    }

    i {
      text-shadow: none;
    }

    &.c {
      color: white;

      @each $key, $val in $colors {
        &-#{$key} {
          background-color: #{$val};
        }
      }
    }
  }

  .wrapper {
    width: 100%;
    height: 100%;
    padding: multiply-size(20px);
    min-width: multiply-size(700px);

    app-business-navigation{
      .business-navigation{
        max-width: multiply-size(720px);
        //@media screen and (max-width: 700px) {
        //  min-width: unset;
        //}
      }
    }

    nav {
      display: flex;
      justify-content: space-between;
      position: absolute;
      left: multiply-size(16px);
      top: multiply-size(16px);
      //width: calc(100% - 64px);
      height: calc(100% - 64px);

      .left,
      .right {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
      }

      .left {
        width: multiply-size(36px);

        .top {
          display: flex;
          gap: multiply-size(8px);
          flex-direction: row-reverse;
          height: multiply-size(36px);
        }
      }

      .right {
        .top,
        .bottom {
          display: flex;
          flex-direction: column;
          gap: multiply-size(8px);
        }
      }
    }

    .content {
      width: multiply-size(498px);
      margin: auto;
      height: 100%;
      //overflow: hidden;

      * {
        @include archivo-narrow;
      }

      .client-list {
        .title-container {
          border: solid 1px black;
          display: flex;
          flex-wrap: wrap;
          align-content: center;
          justify-content: center;
          border-radius: multiply-size(10px) 0;
          height: multiply-size(40px);
          margin-bottom: multiply-size(6px);
          position: relative;

          img {
            width: multiply-size(90px);
            height: multiply-size(28px);
            object-fit: contain;
          }

          p {
            font-size: multiply-size(14px);
            font-weight: bold;
          }

          .badge {
            @include archivo-narrow;
            display: flex;
            justify-content: space-between;
            width: multiply-size(134px);
            height: multiply-size(12px);
            font-size: multiply-size(9px);
            line-height: multiply-size(12px);
            border-radius: 0 0 0 multiply-size(5px);
            padding: 0 multiply-size(8px);
            color: white;
            position: absolute;
            text-align: center;
            top: 0;
            right: 0;

            p {
              margin: 0;
              padding: 0;
              font-size: inherit;
              line-height: inherit;
              font-weight: normal;
            }

            &.red {
              background-color: #ff0909;
            }

            &.yellow {
              background-color: #ff9100;
            }

            &.green {
              background-color: #00813d;
            }
          }
        }

        .activity-name {
          font-size: multiply-size(14px);
          line-height: multiply-size(12px);
          margin-bottom: multiply-size(10px);

          text-align: center;
          width: multiply-size(498px);
        }

        .decorator {
          margin-bottom: multiply-size(5px);
          position: relative;
          background-color: black;
          height: multiply-size(1px);

          p {
            font-size: multiply-size(14px);
            line-height: multiply-size(12px);
            font-weight: bold;
            position: absolute;
            top: multiply-size(-6px);
            margin: 0;
            background-color: white;
            padding-right: multiply-size(6px);
          }
        }

        .progress {
          position: relative;
          padding-top: multiply-size(13px);

          .percentage {
            position: absolute;
            left: calc(50% - 7px);
            margin: 0;
            top: 0;
            font-size: multiply-size(14px);
            line-height: multiply-size(12px);
          }

          .bar {
            width: 100%;
            height: multiply-size(8px);
            border: multiply-size(1px) solid black;
            border-radius: multiply-size(2px);
            position: relative;
            overflow: hidden;
            margin: 0 0 multiply-size(8px);

            .fill {
              top: 0;
              left: 0;
              height: 100%;
              position: absolute;
              background-color: #FF0909;
            }
          }

          .info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: multiply-size(8px);

            div {
              display: flex;
              align-items: center;

              p {
                margin: 0;
                font-size: multiply-size(14px);
                line-height: multiply-size(12px);
                padding: multiply-size(2px) multiply-size(16px) multiply-size(2px) 0;
                border-right: multiply-size(1px) solid black;

                &:last-of-type {
                  border: none;
                  padding: multiply-size(2px) 0 multiply-size(2px) multiply-size(16px);
                }
              }
            }
          }
        }

        .search {

          position: relative;
          margin-bottom: multiply-size(10px);

          .search-bar {

            input {
              height: multiply-size(30px);
              text-align: left;
              border: solid multiply-size(1px) black;
              border-radius: multiply-size(10px) 0;
              width: multiply-size(198px);
            }

            .fa-search {
              @include font-awesome;
              position: relative;
              right: multiply-size(22px);
              font-size: multiply-size(15px);
              top: multiply-size(1px)

            }
          }

        }

        .table {
          width: 100%;
          margin: auto;
          //overflow: hidden;
          height: 100%;

          ow-perfect-scrollbar {
            height: multiply-size(191px);

            @media screen and (max-width: 1080px) {
              max-height: calc(100vh - 222px);
            }
          }

          .table-headers {
            position: relative;
            height: multiply-size(30px);

            button {
              color: black;
              position: absolute;
              height: multiply-size(30px);
              font-size: multiply-size(12px);

              i {
                height: 100%;
                width: multiply-size(20px);
                display: flex;
                justify-content: center;
                align-items: center;
                @include font-awesome;
                text-shadow: none;
              }
            }

            .header-name {
              left: multiply-size(80px);
            }

            .header-id {
              left: 0
            }

            .header-performance {
              right: multiply-size(6px);
            }
          }

          // dirty workaround to override material table without important
          .no-data.no-data.no-data {
            color: #9d9c9c;
          }

          .mat-mdc-table {
            margin-top: multiply-size(10px);
            width: 100%;
            border-spacing: 0px;
            box-shadow: unset;
          }

          .mat-column-name {
            width: multiply-size(331px);
          }

          .mat-column-external_id {
            width: multiply-size(80px);
          }

          .mat-column-performance {
            text-align: center;
            width: multiply-size(78px);
          }

          .mat-mdc-row {
            height: multiply-size(50px);
          }

          .mat-mdc-cell {
            color: black;
            font-size: multiply-size(14px);
            border-bottom: multiply-size(1.5px) solid #000;
            padding: 0;
            line-height: multiply-size(13px);
          }

          .no-data-match {
            text-align: center;
            font-size: 24px;
          }
        }
      }
    }
  }

  .badge {
    @include archivo-narrow;
    min-width: multiply-size(80px);
    height: multiply-size(12px);
    font-size: multiply-size(9px);
    line-height: multiply-size(12px);
    border-radius: 0 0 0 multiply-size(10px);
    color: white;
    position: absolute;
    text-align: left;
    padding: 0 multiply-size(10px);
    top: 0;
    right: 0;

    &.red {
      background-color: #ff0909;
    }

    &.yellow {
      background-color: #ff9100;
    }

    &.green {
      background-color: #00813d;
    }
  }
}
