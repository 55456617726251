@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

.window-a {
  padding: multiply-size(12px) multiply-size(38px) multiply-size(12px) multiply-size(38px);
  box-sizing: border-box;
}

.title {
  @include myriad;
  font-size: multiply-size(12px);
  font-weight: bold;

  p {
    margin-top: 0;
    margin-bottom: multiply-size(5px);
  }
}

.description {
  @include archivo-narrow;
  font-size: multiply-size(10px);
  width: 88%;
  margin: 0 auto;

  p {
    margin-top: multiply-size(3px);
  }
}

.title, .description {
  p {
    text-align: center;
  }
}

.player-code {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: multiply-size(15px) 0 multiply-size(5px) 0;

  span {
    white-space: nowrap;
    @include archivo-narrow;
    font-size: multiply-size(12px);

    &:first-child {
      font-weight: normal !important;
      font-size: multiply-size(10px);
    }

    &.code {
      font-size: multiply-size(14px);
      @include myriad;
      padding-top: multiply-size(3px);
      margin-left: multiply-size(5px);
      font-weight: bold;
    }
  }
}

.prizes-list {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @include archivo-narrow;

  p {
    text-align: center;
    font-size: multiply-size(10px);
  }
}

.thead, .tbody {
  width: 86%;
  margin: 0 auto;
  box-sizing: border-box;
}

.thead {
  padding-right: multiply-size(3px);

  .tr {
    background-color: #e2e8ee;
    height: multiply-size(15px);
  }
}

.tbody {
  height: multiply-size(66px);

  .tr {
    height: multiply-size(18px);
  }
}

.tr {
  display: flex;
  align-items: center;

  .t-1, .t-3 {
    width: 30%;
  }

  .t-2 {
    border-right: multiply-size(0.5px) solid $color-K;
    border-left: multiply-size(0.5px) solid $color-K;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-left: multiply-size(2px);
    }
  }

  .td {
    padding: multiply-size(5px) multiply-size(4px);
    font-size: multiply-size(9px);
  }

  .th {
    padding: multiply-size(3px) multiply-size(4px);
    font-size: multiply-size(8px);
  }

  .td, .th {
    text-align: center;
  }
}

.img-currency-small {
  width: multiply-size(14px);
  height: multiply-size(14px);
}

.img-currency-big {
  width: multiply-size(38px);
  height: multiply-size(38px);
}

.player-prize {
  @include archivo-narrow;
  font-size: multiply-size(12px);
  font-weight: bold;
  margin-top: multiply-size(5px);

  .prize-box {
    display: flex;
    align-items: center;
    justify-content: center;
    @include myriad;
    font-size: multiply-size(13px);
    box-sizing: border-box;
    margin-bottom: multiply-size(6px);
    height: multiply-size(24px);

    .s1 {
      border-right: none !important;
      border-top-left-radius: multiply-size(10px);
      border-bottom-left-radius: multiply-size(10px);
      padding: multiply-size(3px) multiply-size(8px) multiply-size(1px) multiply-size(10px);
    }

    .s2 {
      border-left: none !important;
      border-top-right-radius: multiply-size(10px);
      border-bottom-right-radius: multiply-size(10px);
      padding: multiply-size(3px) multiply-size(10px) multiply-size(1px) multiply-size(8px);
      background-color: $color-K;
      color: #FFFFFF;
      min-width: multiply-size(80px);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      img {
        @include absolute-center-horizontal;
        left: multiply-size(15px);
      }
    }

    .s1, .s2 {
      height: 100%;
      box-sizing: border-box;
      border: multiply-size(2px) solid $color-K;
    }
  }
}

ow-perfect-scrollbar .ps-content {
  padding-right: multiply-size(3px);
}

ow-perfect-scrollbar {
  width: 100% !important;

  &.scroll-indicator:after {
    width: multiply-size(6px, '100% - ') !important;
    height: multiply-size(60px);
    background: linear-gradient(0deg, $color-I 10%, rgba($color-I, 0.02) 100%);
  }

  .ps__thumb-y {
    background-color: rgba($color-K, 0.8) !important;
  }
}
