@keyframes shake-animation {
  0% {
    transform: rotate(15deg);
  }
  4% {
    transform: rotate(-10deg);
  }
  8% {
    transform: rotate(5deg);
  }
  12% {
    transform: rotate(-5deg);
  }
  16% {
    transform: rotate(0deg);
  }

  20% {
    transform: rotate(15deg);
  }
  24% {
    transform: rotate(-10deg);
  }
  28% {
    transform: rotate(5deg);
  }
  32% {
    transform: rotate(-5deg);
  }
  36% {
    transform: rotate(0deg);
  }

  40% {
    transform: rotate(15deg);
  }
  44% {
    transform: rotate(-10deg);
  }
  48% {
    transform: rotate(5deg);
  }
  52% {
    transform: rotate(-5deg);
  }
  56% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes leg-animation {
  90% {
    transform: rotate(0deg);
  }
  95% {
    transform: rotate(6deg);
  }
  100% {
    transform: rotate(0);
  }
}
