@import "src/styles/functions";

app-gallery-header {
  .gallery-header {
    background: rgba(0, 119, 38, 1) var(--asset--ui-background-photo-gallery-png);

    .gallery-container {
      .logo {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: var(--asset--ui-logo-alternative-png);
        width: 280px;
        height: 160px;
      }
    }
  }
}

