@import "src/styles/functions";

.ow-dialog {
  m-ui-close-button {
    .exit-bar {
      top: multiply-size(-5px);
      right: multiply-size(-10px);
    }
  }
}

m-ui-close-button {
  button {
    &.exit {
      border: none;

      &.small {
        width: multiply-size(30px);
        height: multiply-size(30px);
      }

      &.normal {
        width: multiply-size(40px);
        height: multiply-size(40px);
      }

      i {
        font-size: multiply-size(10px);
        color: $color-K;
        text-shadow: none;
      }
    }
  }

  @import "custom/style";
}
