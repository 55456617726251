@import "src/styles/functions";
@import "../../../../../../../../../styles/mixins";
@import "../../../../../../../../../styles/variables/core/variables";

hud-star-missions {
  $arrow-size: multiply-size(2px);

  @include archivo-narrow;
  height: 100%;
  box-sizing: border-box;
  padding: multiply-size(12px) multiply-size(16px);
  display: flex;
  flex-direction: column;
  align-items: center;
  letter-spacing: 0;

  .stars {
    display: flex;
    margin-bottom: multiply-size(5px);

    .star {
      margin-right: multiply-size(10px);
      height: multiply-size(40px);
      width: multiply-size(40px);
      background-image: var(--asset--ui-star_empty-png);
      background-size: contain;
      background-repeat: no-repeat;

      &.star-full {
        background-image: var(--asset--ui-star_full-png);
      }

      &.star-half {
        background-image: var(--asset--ui-star_half-png);
      }
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: multiply-size(9px);
    margin-bottom: multiply-size(6px);

    span {
      @include myriad();
      font-size: multiply-size(13px);
      font-weight: bold;
    }
  }

  .description {
    font-size: multiply-size(9px);
    width: 100%;
    padding: 0 multiply-size(10px);
    text-align: center;
    margin-bottom: multiply-size(12px);
  }

  .progress {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    .progress-title {
      font-size: multiply-size(7px);
      margin-bottom: multiply-size(4px);
    }

    .tabs {
      display: flex;
      align-items: center;
      position: relative;
      top: auto;
      width: 100%;
      height: multiply-size(18px);
      border: solid multiply-size(2px) $color-K;
      margin-bottom: multiply-size(7px);
      box-sizing: border-box;

      .tab {
        display: flex;
        flex: 1;
        height: 100%;
        position: relative;
        text-align: center;
        pointer-events: none;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        font-size: multiply-size(12px);
        @include myriad();
        font-weight: bold;
        letter-spacing: 0;

        &.active {
          background: #FFDD00;

          &:after {
            background-color: #EEEBE4;
            position: absolute;
            top: multiply-size(8px);
            z-index: 10;
            border: none;
          }
        }

        &.complete {
          background: $color-S;
        }

        .tab-separator {
          display: flex;
          flex-direction: column;
          position: absolute;
          justify-content: space-between;
          right: multiply-size(-2px);
          z-index: 2;
          height: 100%;

          .separator-arrow {
            width: 0;
            height: 0;

            &.arrow-down {
              border-left: $arrow-size solid transparent;
              border-right: $arrow-size solid transparent;
              border-top: $arrow-size solid #000000;
            }

            &.arrow-up {
              border-left: $arrow-size solid transparent;
              border-right: $arrow-size solid transparent;
              border-bottom: $arrow-size solid black;
            }
          }
        }
      }
    }
  }

  .missions {
    display: flex;
    width: 100%;
    background-color: #E8F3FF;
    padding: multiply-size(8px) multiply-size(8px) multiply-size(4px) multiply-size(10px);
    align-items: center;
    justify-content: center;
    height: multiply-size(100px);
    box-sizing: border-box;
    z-index: 11;

    .missions-info {
      font-size: multiply-size(9px);
      width: multiply-size(146px);
      height: multiply-size(72px);
    }

    .missions-stages {
      flex: 1;
      margin-left: multiply-size(10px);

      .stage {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: multiply-size(4px);
        font-size: multiply-size(9px);

        .stage-box {
          display: flex;
          align-items: center;

          &.stage-box-active {
            color: #62B500;

            .box {
              &:after {
                content: '';
                background-image: var(--asset--ui-icon-check-png);
                background-repeat: no-repeat;
                background-size: contain;
                width: multiply-size(15px);
                height: multiply-size(15px);
                position: absolute;
              }
            }
          }

          .box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: multiply-size(16px);
            height: multiply-size(16px);
            border: solid multiply-size(2px) $color-K;
            border-radius: multiply-size(4px);
            font-size: multiply-size(7px);
            margin-right: multiply-size(7px);
            background-color: #E8F3FF;
            box-sizing: border-box;
          }
        }
      }
    }
  }

  .success {
    @include myriad();
    text-align: center;
    font-weight: bold;
    font-size: 20px;

    p {
      font-size: multiply-size(12px);
    }
  }

  .all-stars-collected {
    margin-bottom: multiply-size(25px) !important;
  }

  .mt-auto {
    margin-top: auto;
  }

  .no-item {
    font-size: 20px;
    font-weight: bold;
    margin-top: 170px;
  }
}
