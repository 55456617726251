.business-dialog {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
}

.cdk-overlay-container {
  .cdk-overlay-backdrop {
    transition: initial !important;
    background: rgba(0, 0, 0, 0.68) !important;
  }
}
.ow-dialog {
  .top-bar {
    .title {
      @include mikado();
    }
  }
}
