@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-prepaid-cards-list {
  * {
    box-sizing: border-box;
  }

  .ow-dialog {
    padding: multiply-size(20px) multiply-size(30px);

    .top-bar {
      .title {
        text-transform: none;
        @include mikado();
      }
    }
  }

  .content {
    height: multiply-size(175px);

    p {
      @include archivo-narrow;
      margin: 0 0 multiply-size(25px);
      font-size: multiply-size(10px);
      line-height: multiply-size(12px);
    }

    .list-wrapper {
      display: flex;
      flex-direction: column;
      @include archivo-narrow;
      text-align: left;
      font-weight: normal;
      width: 100%;
      border-collapse: collapse;
      height: multiply-size(175px);

      ow-perfect-scrollbar {
        width: calc(100% + 15px * var(--multiply)) !important
      }

      .header,
      .card {
        display: flex;

        p {
          width: multiply-size(80px);

          &:last-of-type {
            width: multiply-size(94px);
            margin-left: auto;
            text-align: right;
          }
        }
      }

      .header {
        font-size: multiply-size(10px);
        line-height: multiply-size(12px);
        color: #7E7E7E;

        p {
          @include archivo-narrow;
          font-weight: normal;
          margin: 0;
          padding-bottom: multiply-size(4px);
        }
      }

      .body {
        display: flex;
        flex-direction: column;
        font-size: multiply-size(10px);
        line-height: multiply-size(12px);

        .card {
          display: flex;
          align-items: center;
          height: multiply-size(50px);

          &:not(:last-of-type) {
            border-bottom: multiply-size(1px) solid #ECECEC;
          }

          p {
            @include archivo-narrow;
            font-weight: normal;
            margin: 0;

            &:last-of-type {
              cursor: pointer;

              &:hover {
                i {
                  opacity: 0.2;
                }
              }
            }

            i {
              height: multiply-size(20px);
              width: multiply-size(20px);
              border: multiply-size(2px) solid #000;
              border-radius: multiply-size(2px);
              font-size: multiply-size(14px);

              &:before {
                display: block;
                margin: multiply-size(1px) auto 0;
                width: 100%;
                text-align: center;
                height: 100%;
                font-weight: bold;
              }
            }
          }


          .active {
            color: #00813D;
          }

          .inactive {
            color: #FF0909;
          }
        }
      }
    }
  }
}
