@import "src/styles/functions";
@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-message-details-hidden-reward {
  @import "../../styles/style";
  @import "variables";

  .hidden-reward-container {
    .hidden-reward-image {
      display: flex;
      justify-content: center;

      img {
        max-width: 100%;
        object-fit: contain;
      }
    }

    .inner-html-description {
      margin: multiply-size(10px) 0;
      @include mikado();
      font-weight: bold;
      font-size: multiply-size(12px);
      text-align: center;
    }

    m-ui-separator {
      margin: multiply-size(15px) 0;
    }

    .parameters {
      display: flex;
      justify-content: center;

      m-ui-product,
      m-ui-currency {
        margin: 0 multiply-size(9px);
      }
    }
  }

  @import "custom/style";
}
