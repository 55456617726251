@import "src/styles/functions";
@import "variables/core/variables";
@import "mixins";

form {
  width: 100%;
}

input[type="text"],
input[type="password"],
input[type="number"] {
  border: multiply-size(1px) solid $color-base;
  background: transparent;
  height: multiply-size(28px);
  width: 100%;
  box-sizing: border-box;
  padding: 0 multiply-size(10px);
  @include archivo-narrow;
  font-size: multiply-size(12px);
  color: $color-base;
  text-align: center;

  $color-placeholder: #808080;

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $color-placeholder;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: $color-placeholder;
  }

  :-ms-input-placeholder {
    /* IE 10+ */
    color: $color-placeholder;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: $color-placeholder;
  }

  &:disabled {
    opacity: 0.5;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.ow-radio-container {
  display: flex;
  align-items: center;

  .ow-radio {
    display: block;
    position: relative;
    width: multiply-size(32px);
    height: multiply-size(32px);
    min-width: multiply-size(32px);
    min-height: multiply-size(32px);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: multiply-size(5px);

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      &:checked ~ .checkmark {
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  .label-text {
    margin-left: multiply-size(4px);
    font-size: multiply-size(14px);
    font-weight: bold;
  }
}

@import "custom/forms";
