@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-tasks-daily {
  p{
    margin: unset;
  }

  * {
    box-sizing: border-box;
  }

  .ow-dialog {
    &.primary {
      width: multiply-size(756px);
      height: multiply-size(345px);
      box-shadow: unset;
    }
  }

  .round-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: multiply-size(10px) 0;
    font-size: multiply-size(20px);
    line-height: multiply-size(12px);
    height: 100%;
    padding: 0 multiply-size(20px);

    &:hover {
      filter: brightness(1.2);
    }

    $colors: (
      "green": #00813d,
      "light-green": #019748,
      "lazure": #00a482,
    );

    p {
      @include archivo-narrow;
      font-size: multiply-size(12px);
      line-height: multiply-size(11px);
      margin: 0;
    }

    &.fixed {
      padding: 0;
      min-width: multiply-size(40px);
      min-height: multiply-size(40px);
    }

    &.round-reverse {
      border-radius: 0 multiply-size(10px);
    }

    i {
      text-shadow: none;
    }

    &.c {
      color: white;

      @each $key, $val in $colors {
        &-#{$key} {
          background-color: #{$val};
        }
      }
    }
  }

  .wrapper {
    width: calc(756px * var(--multiply));
    height: calc(345px * var(--multiply));
    position: relative;
    padding: calc(16px * var(--multiply));
    min-width: calc(700px * var(--multiply));
    app-business-navigation{
      .business-navigation{
        max-width: multiply-size(720px);
        //@media screen and (max-width: 700px) {
        //  min-width: unset;
        //}
      }
    }

    .content {
      width: multiply-size(498px);
      margin: auto;
      height: 100%;
      overflow: visible;

      * {
        @include archivo-narrow;
        color: black;
      }

      //.swiper-navigation {
      //  position: sticky;
      //  width: 100%;
      //  * {
      //
      //  }
      .arrow-pagination {
        @include font-awesome;
        position: absolute;
        font-size: multiply-size(20px);
        cursor: pointer;
      }
      .left {
        top: multiply-size(28px);
        left: multiply-size(94px);
      }
      .right {
        top: multiply-size(28px);
        right: multiply-size(94px);
      }
      //}

      .title {
        position: relative;
        border: solid 1px black;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        border-radius: multiply-size(10px) 0;
        height: multiply-size(40px);

        img {
          width: multiply-size(90px);
          height: multiply-size(28px);
        }

        p {
          font-size: multiply-size(14px);
          font-weight: bold;
        }
      }

      .task {
        font-size: multiply-size(14px);
        line-height: multiply-size(12px);
        margin-top: multiply-size(6px);
        text-align: center;
        width: 100%;
      }

      .decorator {
        margin-bottom: multiply-size(5px);
        position: relative;
        background-color: black;
        height: multiply-size(1px);

        p {
          font-size: multiply-size(14px);
          line-height: multiply-size(12px);
          font-weight: bold;
          position: absolute;
          top: multiply-size(-6px);
          margin: 0;
          background-color: white;
          padding-right: multiply-size(6px);
        }
      }

      .progress {
        position: relative;
        padding-top: multiply-size(13px);

        .percentage {
          position: absolute;
          left: calc(50% - 7px);
          margin: 0;
          top: 0;
          font-size: multiply-size(14px);
          line-height: multiply-size(12px);
        }

        .bar {
          width: 100%;
          height: multiply-size(8px);
          border: multiply-size(1px) solid black;
          border-radius: multiply-size(2px);
          position: relative;
          overflow: hidden;
          margin: 0 0 multiply-size(8px);

          .fill {
            top: 0;
            left: 0;
            height: 100%;
            position: absolute;
            background-color: #ff0909;
          }
        }

        .info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: multiply-size(8px);

          div {
            display: flex;
            align-items: center;

            p {
              margin: 0;
              font-size: multiply-size(14px);
              line-height: multiply-size(12px);
              padding: multiply-size(2px) multiply-size(16px) multiply-size(2px) 0;
              border-right: multiply-size(1px) solid black;

              &:last-of-type {
                border: none;
                padding: multiply-size(2px) 0 multiply-size(2px) multiply-size(16px);
              }
            }
          }
        }
      }

      .search {
        margin-top: multiply-size(15px);
        position: relative;
        .search-bar {
          input {
            text-align: left;
            border: solid multiply-size(1px) black;
            border-radius: multiply-size(10px) 0;
            width: multiply-size(198px);
          }
          .fa-search {
            @include font-awesome;
            position: relative;
            right: multiply-size(22px);
            font-size: multiply-size(15px);
            top: multiply-size(1px);
          }
        }
      }

      ow-perfect-scrollbar {
        width: 100%;
        margin: auto;
        overflow: hidden;
        height: 100%;
        height: multiply-size(245px);

        .table {
          margin-top: multiply-size(6px);
          display: flex;
          margin-left: multiply-size(20px);
          margin-right: multiply-size(20px);
          justify-content: space-between;
          .plan {
            @include archivo-narrow;
            box-sizing: border-box;
            border-radius: multiply-size(10px) 0 multiply-size(10px) 0;
            background-color: black;
            color: white;
            display: flex;
            flex-direction: column;
            border: solid 1px black;
            width: multiply-size(68px);
            height: 100%;

            .date {
              border-radius: multiply-size(10px) 0 0 0;
              margin: 0;
              font-size: multiply-size(14px);
              font-weight: bold;
              padding: multiply-size(3px) multiply-size(3px);
              text-align: center;
              background-color: black;
              width: 100%;
              color: white;
            }

            .sale {
              background-color: black;
              color: black;
              border: solid 1px black;

              .percentage {
                background-color: white;
                text-align: center;
                padding: multiply-size(2px);
                margin: 0;
                font-size: multiply-size(14px);
                font-weight: bold;

                &.rounded {
                  border-radius: multiply-size(10px) 0 0 0;
                }
              }
              .prize {
                background-color: white;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-left: multiply-size(8px);
                padding-right: multiply-size(8px);
                img {
                  height: multiply-size(14px);
                  width: multiply-size(14px);
                }
                p {
                  margin: 0;
                  padding: multiply-size(2px);
                  font-size: multiply-size(14px);
                }
              }
              &.green-background {
                background-color: #a7ee9a;
                * {
                  background-color: #a7ee9a;
                }
              }
            }
            .fullfilment {
              background-color: black;
              border-radius: 0 0 multiply-size(10px) 0;
              .text {
                margin-top: multiply-size(6px);
                text-align: center;
                font-size: multiply-size(12px);
                margin-bottom: 0;
                color: white;
              }
              .number {
                color: white;
                margin-top: multiply-size(4px);
                text-align: center;
                font-size: multiply-size(14px);
                font-weight: bold;
                margin-bottom: multiply-size(6px);
                border-radius: 0 0 multiply-size(10px) 0;
              }
            }
          }
          &.narrow-columns {
            justify-content: center;
            gap: multiply-size(6px);
          }
        }
        .total-prizes {
          color: black;
          margin-top: multiply-size(12px);
          display: flex;
          justify-content: center;
          align-items: baseline;
          font-size: multiply-size(14px);
          .prizes {
            display: flex;
            align-items: baseline;
            .value {
              font-weight: bold;
              padding-left: multiply-size(3px);
              padding-right: multiply-size(3px);
            }
            img {
              max-height: multiply-size(14px);
              max-width: multiply-size(14px);
            }
          }
          .text{
            font-size: multiply-size(14px);
          }
        }
      }
    }
  }
  .faded {
    filter: brightness(1.2);
  }
}
