.hidden {
  visibility: hidden;
}

.padding-ol {
  padding-left: multiply-size(10px);
}

// BUSINESS
.round-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: multiply-size(10px) 0;
  font-size: multiply-size(20px);
  line-height: multiply-size(12px);
  height: 100%;
  padding: 0 multiply-size(20px);

  &:hover {
    filter: brightness(1.2);
  }


  $colors: (
    'green': #00813D,
    'light-green': #019748,
    'lazure': #00A482,
  );

  p {
    @include archivo-narrow;
    font-size: multiply-size(12px);
    line-height: multiply-size(11px);
    margin: 0;
  }

  &.fixed {
    padding: 0;
    min-width: multiply-size(40px);
    min-height: multiply-size(40px);
  }

  &.round-reverse {
    border-radius: 0 multiply-size(10px);
  }

  i {
    text-shadow: none;
  }

  &.c {
    color: white;

    @each $key, $val in $colors {
      &-#{$key} {
        background-color: #{$val};
      }
    }
  }

  .far.fa-arrow-to-left {
    rotate: 90deg;
  }
}
