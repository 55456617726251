app-tasks-list {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  * {
    box-sizing: border-box;
  }
  .wrapper {
    width: calc(756px * var(--multiply));
    height: calc(345px * var(--multiply));
    position: relative;
    padding: calc(16px * var(--multiply));

    app-business-navigation{
      .business-navigation{
        max-width: multiply-size(720px);
        .left{
          .month-selector{
            .month{
              p{
                width: multiply-size(110px);
              }
            }
          }
        }
      }
    }

    .content {
      max-width: multiply-size(498px);
      margin: auto;
      height: 100%;
      overflow: visible;

      ow-perfect-scrollbar {
        width: 100%;
        overflow: hidden;
        height: multiply-size(345px);
        @media screen and (max-width: 1080px) {
          height: multiply-size(264px);
        }

        loading {
          z-index: unset;
        }
        .tasks {
          .task,
          .daily-task,
          .week-plan,
          .self-service {
            @include archivo-narrow;
            font-size: multiply-size(14px);
            line-height: multiply-size(12px);
            display: flex;
            align-items: center;
            border-bottom: multiply-size(1px) solid black;
            padding: multiply-size(6px) 0;
            position: relative;
            height: multiply-size(68px);

            p {
              margin: 0;
            }

            .logo {
              display: flex;
              justify-content: center;
              width: multiply-size(90px);
              height: multiply-size(40px);

              img {
                object-fit: contain;
                height: 100%;
                width: 100%;
              }
            }

            .title,
            .day {
              display: flex;
              justify-content: center;
              align-items: center;
              width: multiply-size(90px);
              height: multiply-size(40px);

              &.day {
                width: multiply-size(144px);
                padding-left: multiply-size(20px);
              }

              p {
                font-size: multiply-size(14px);
                font-weight: bold;
                color: black;
                text-align: center;
              }
            }

            .progress {
              width: multiply-size(125px);
              margin-left: multiply-size(20px);

              .info,
              .points {
                display: flex;
                justify-content: space-between;
              }

              .bar {
                width: 100%;
                height: multiply-size(8px);
                border: multiply-size(1px) solid black;
                border-radius: multiply-size(2px);
                position: relative;
                overflow: hidden;
                margin: multiply-size(4px) 0;

                .fill {
                  top: 0;
                  left: 0;
                  height: 100%;
                  position: absolute;
                  background-color: #ff0909;
                }
              }
            }

            .currencies {
              display: flex;
              flex-direction: column;
              margin-left: multiply-size(14px);
              gap: multiply-size(3px);

              .currency {
                display: flex;
                align-items: center;

                .description {
                  display: flex;
                  align-items: center;
                  width: multiply-size(175px);

                  &.small {
                    width: multiply-size(100px);
                  }

                  img {
                    object-fit: contain;
                    height: multiply-size(14px);
                    width: multiply-size(14px);
                  }

                  .name {
                    margin-left: multiply-size(4px);
                    width: multiply-size(110px);
                  }
                }

                .value,
                .prizes {
                  display: flex;
                  align-items: center;

                  .amount {
                    margin-left: multiply-size(10px);
                    width: multiply-size(25px);
                  }

                  .percentage {
                    margin-left: multiply-size(10px);
                  }
                  &.value{
                    font-weight: bold;
                  }
                }

                .max-amount {
                }
              }
            }

            button {
              display: flex;
              justify-content: center;
              align-items: center;
              width: multiply-size(40px);
              margin-left: auto;

              i {
                text-shadow: none;
                color: #358f42;
              }

              &:hover {
                filter: brightness(1.2);
              }
            }

            .badge {
              @include archivo-narrow;
              padding-left: multiply-size(8px);
              padding-right: multiply-size(8px);
              height: multiply-size(12px);
              font-size: multiply-size(9px);
              line-height: multiply-size(12px);
              border-radius: 0 0 multiply-size(5px);
              width: multiply-size(148px);
              color: white;
              position: absolute;
              text-align: right;
              top: 0;
              right: 0;

              &.red {
                background-color: #ff0909;
              }

              &.yellow {
                background-color: #ff9100;
              }

              &.green {
                background-color: #00813d;
              }
            }
          }
        }
        .tasks-empty {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: multiply-size(300px);
          p {
            @include archivo-narrow;
            font-size: multiply-size(14px);
            font-weight: bold;
          }
        }
      }
    }
  }

  .faded {
    filter: brightness(1.2);
  }
  .top-border {
    border-top: multiply-size(1px) solid black;
  }
  .button-disabled {
    opacity: 1;
  }
}
