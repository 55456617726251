app-plan-list {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  * {
    box-sizing: border-box;
  }
  .ow-dialog {
    &.primary {
      width: multiply-size(756px);
      height: multiply-size(345px);
      box-shadow: unset;
      overflow: auto;
    }
  }
  .wrapper {
    width: 100%;
    height: 100%;
    padding: multiply-size(16px);
    min-width: multiply-size(700px);
    //@media screen and (max-width: 700px) {
    //  min-width: unset;
    //}
    //
    app-business-navigation{
      .business-navigation{
        max-width: multiply-size(720px);
        //@media screen and (max-width: 700px) {
        //  min-width: unset;
        //}
      }
    }

    .content {
      width: multiply-size(484px);
      margin: auto;
      height: 100%;
      //overflow: hidden;

      ow-perfect-scrollbar {
        height: multiply-size(300px);
        //@media screen and (max-width: 1080px) {
        //  max-height: calc(100% - 25px);
        //
        //}
      }

      .vendor {
        @include archivo-narrow;
        height: multiply-size(75px);
        font-size: multiply-size(11px);
        line-height: multiply-size(12px);
        display: flex;
        flex-direction: column;
        border-top: multiply-size(1px) solid black;
        //padding: 0 0 multiply-size(11px);
        position: relative;
        cursor: pointer;
        width: multiply-size(484px);

        p {
          margin: 0;
        }

        .badge-container {
          background-color: #ececec;
          display: flex;
          justify-content: flex-end;
          width: 100%;
          //min-height: multiply-size(6px);
          border-radius: 0 0 multiply-size(5px);

          .badge {
            @include archivo-narrow;
            display: flex;
            justify-content: flex-end;
            width: multiply-size(195px);
            height: multiply-size(14px);
            font-size: multiply-size(12px);
            line-height: multiply-size(12px);
            border-radius: 0 0 multiply-size(5px);
            padding: 0 multiply-size(8px);
            color: white;
            font: normal normal normal multiply-size(12px)/multiply-size(12px);
            letter-spacing: 0px;
            //position: absolute;
            text-align: center;
            //top: 0;
            //right: 0;
            p {
              text-align: end;
              font-size: multiply-size(12px);
            }

            &.red {
              background-color: #ff0909;
            }

            &.yellow {
              background-color: #ff9100;
            }

            &.green {
              background-color: #00813d;
            }
          }
        }

        .info {
          font-size: multiply-size(14px);
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
        }

        .logo {
          display: flex;
          justify-content: center;
          width: multiply-size(90px);
          height: multiply-size(44px);

          img {
            object-fit: contain;
            height: 100%;
            width: 100%;
          }

          .company-name {
            display: none;
            margin: multiply-size(2px) 0;
            text-align: center;

            &:only-child {
              display: flex;
              align-items: center;
            }

            p {
              @include archivo-narrow;
              font-size: multiply-size(14px);
              line-height: multiply-size(16px);
              font-weight: bold;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
        }

        .progress {
          width: multiply-size(150px);
          margin-left: multiply-size(20px);

          .info,
          .points {
            display: flex;
            justify-content: space-between;
          }

          .bar {
            width: 100%;
            height: multiply-size(8px);
            border: multiply-size(1px) solid black;
            border-radius: multiply-size(2px);
            position: relative;
            overflow: hidden;
            margin: multiply-size(4px) 0;

            .fill {
              top: 0;
              left: 0;
              height: 100%;
              position: absolute;
              background-color: #ff0909;
            }
          }
        }

        .currencies {
          font-size: multiply-size(14px);
          display: flex;
          flex-direction: column;
          margin-left: auto;
          gap: multiply-size(3px);

          .currency {
            display: flex;
            align-items: center;

            .name {
              margin-left: multiply-size(3px);
              width: multiply-size(75px);
            }

            .value {
              margin-right: multiply-size(4px);
              width: multiply-size(35px);
              font-weight: normal;
              text-align: end;
            }

            .amount {
              margin-left: multiply-size(2px);
              width: multiply-size(25px);
            }

            .percentage {
              margin-left: multiply-size(10px);
            }

            .max-amount {
              margin-left: multiply-size(2px);
              min-width: multiply-size(35px);
              text-align: end;
            }
          }
        }

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: multiply-size(40px);
          height: multiply-size(40px);
          margin-left: multiply-size(4px);

          i {
            line-height: multiply-size(40px);
            text-shadow: none;
            color: #358f42;
            font-size: multiply-size(20px);
          }

          &:hover {
            filter: brightness(1.2);
          }
        }
      }
      .tasks-empty {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: multiply-size(300px);
        max-height: calc(100vh - 100px);
        p {
          @include archivo-narrow;
          font-size: multiply-size(14px);
          font-weight: bold;
        }
      }
    }
  }
  .pointer {
    cursor: pointer;
  }
  .button-disabled {
    opacity: 1;
  }
}
